@import './colors.css';

.PayNPlay {
  color: var(--white);

  &.bonus {
    .Bonus {
      .BonusSelector {
        display: flex;
        justify-content: center;
        &__bonus {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: var(--light-blue);
          margin: 20px 0;
          max-width: 500px;
          padding-bottom: 20px;
          border-radius: 8px;
          overflow: hidden;

          h3 {
            font-size: 2em;
          }
          a {
            order: 2;
          }
          button {
            margin-block: var(--pnp-spacing);
          }

          &__content {
            padding: 0px 20px;
            text-align: left;
          }

          &__image {
            img {
              max-width: unset;
            }
          }
        }
      }
    }
    button {
      color: var(--white);
    }
  }
}

.is-pnp .PayNPlayContainer {
  .error_message.visible,
  .minimum_amount,
  .error-message {
    margin-bottom: 15px;
    font-weight: 600;
  }

  form {
    flex-flow: column;
  }

  .tg-input {
    margin: 0;
    input {
      width: 100% !important;
      background: none;
      color: var(--white);
      font-size: 30px;
      border: none;
      border-bottom: 1px solid var(--white);
      padding-bottom: 15px;
      caret-color: var(--primary-color);
      border-radius: 0;

      @media (max-width: 992px) {
        font-size: 30px !important;
      }

      @media (max-width: 540px) {
        font-size: 20px !important;
      }
    }
  }

  .AcceptUpdatedTaC {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: var(--white);
    }
  }

  .disclaimer {
    font-weight: 500;
  }

  .question {
    margin-top: 0;
    font-weight: 500;

    .button.secondary.small {
      background: none;
      border-radius: 0;
      box-shadow: none;
      color: var(--white);
      font-size: 14px;
      font-weight: 500 !important;
      text-transform: unset;
      display: inline-flex;
      padding: 4px;
      text-decoration: underline;
      font-style: italic;
    }
  }

  .InlinePnp {
    .button {
      margin-bottom: 15px;
    }

    .tg-input {
      input {
        margin-bottom: 0;
        font-weight: 800;
        height: 80px;
        font-size: 60px;
        border: none;
        text-align: center;

        @media (max-width: 992px) {
          font-size: 60px !important;
        }

        @media (max-width: 540px) {
          font-size: 2em !important;
        }
      }
    }
  }

  .DetailsStep {
    > * {
      margin-bottom: 20px;
    }
    .save-button {
      margin-bottom: 15px;
    }

    .phone-wrapper {
      width: 285px;

      @media (max-width: 540px) {
        width: 100%;
      }

      .tg-input,
      .tg-select {
        margin: 0;
        .tg-select__status-text,
        .tg-input__status-text {
          display: none;
        }
      }

      .tg-input .Input {
        input {
          background-image: url('../images/input-phone.svg');
          background-repeat: no-repeat;
          padding-left: 30px;
          background-position-y: center;
        }
      }

      .calling-code {
        background: transparent;
        border-bottom: 1px solid var(--white);
        width: 180px;
        margin-right: 15px;
        input {
          height: 40px;
          color: var(--white);
          font-weight: 800;
          font-family: 'Baloo', 'Fira Sans', sans-serif;
          line-height: 40px;
          font-size: 18px;
          background: none;
          padding-left: 10px;
          font-style: italic;
        }

        select {
          cursor: pointer;
          background-color: transparent;
          padding-bottom: 5px;
          color: var(--white);
          font-weight: 800;
          font-size: 18px;
          font-style: italic;
        }
      }
    }

    .tg-input {
      width: 285px;

      .tg-input__status-text {
        display: none;
      }

      .Input {
        input {
          font-size: 18px !important;
          padding-bottom: 5px;
          text-align: left;
          font-weight: 800;

          &::placeholder {
            font-style: italic;
            text-transform: uppercase;
          }
        }
      }

      input[type='email'] {
        background-image: url('../images/input-email.svg');
        background-repeat: no-repeat;
        padding-left: 40px;
        background-position-y: center;
      }

      @media (max-width: 540px) {
        width: 100%;
      }
    }
  }

  .communication-consent {
    justify-content: center;
    margin-top: 0;
    width: auto;

    .Checkbox {
      .flex {
        justify-content: center;
      }

      label {
        margin: 0;
        width: initial;
      }
    }
  }

  .Prompt {
    .prompt-buttons {
      @media (max-width: 466px) {
        display: flex;
        flex-direction: column;

        .button {
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }
}
