.SignUpContainer {
  color: var(--white);

  .Layout {
    color: var(--white);
    &__step {
      background: linear-gradient(to right, #5bd2fe 5%, #2aa5ff 70%);

      &__wrapper {
        .Icons {
          color: var(--white);

          i.icon-money-bill::before {
            content: '\e91a';
          }

          &__icon {
            &--active {
              color: #42d19d;
            }
          }
        }

        &__content {
          * {
            color: var(--white) !important;
          }

          p {
            font-size: 0.6em;
          }
        }
      }
    }

    &--deposit {
      text-align: center;

      .Deposit {
        display: flex;
        flex-direction: column;

        .button.primary {
          margin-bottom: 10px;
        }
      }
    }
  }
}
