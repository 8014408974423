@import './colors.css';

.Loader {
  .loader-icon {
    width: 200px;
    height: 200px;
    background-image: url('../images/logo.svg');
    background-size: 200px auto;
  }

  .loading-spinner {
    margin: 20px auto 0;
  }
}
