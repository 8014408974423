.MigrationContainer {
  .main-content-container {
    color: #434343;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #434343;
    }
    text-align: center;
    padding: 50px 0;

    @media (max-width: 992px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
