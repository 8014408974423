.SourceOfWealthContainer {
  .checkbox label {
    margin-left: 0;
  }

  .other {
    textarea {
      border: 1px solid var(--tertiary-color);
      background: var(--secondary-color);
      color: var(--white);
      border-radius: var(--boder-radius);
      font-size: 14px;
      font-style: italic;

      &::placeholder {
        color: var(--white);
      }
    }
  }

  .income {
    .checkbox label {
      width: 80%;
    }
  }

  @media (max-width: 992px) {
    .button-wrapper {
      flex-direction: column;

      .button {
        width: 100%;
        margin: 0;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background: var(--white);
  }
}
