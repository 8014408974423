.Popup {
  &__header {
    color: var(--white);
  }

  &__content {
    overflow: auto;
    font-style: italic;
    color: var(--black);
    border-radius: var(--border-radius); 

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--black);
    }

    .Select,
    .Input {
      border-radius: 5px;
      border: solid 1px var(--black);

      &__label {
        color: var(--black);
      }
    }
  }

  &__children {
    color: var(--black);
  }
}
