@import '../variables.css';

.CasinoContainer {
  .main-content-container {
    .content {
      padding-top: 20px;
    }

    .Banners {
      height: 35vh;
      min-height: 600px;

      @media (max-width: 992px) {
        height: 35vh;
        min-height: unset;
      }

      @media (max-width: 750px) {
        height: 40vh;
      }
      
      .background {
        background-position: center !important;
      }
      .content {
        bottom: 0 !important;
        top: 50%;
        right: initial;
        padding: 0 50px 30px;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        transform: TranslateY(-50%) !important;

        @media (max-width: 992px)
        {
          top: 0;
          left: 0 !important;
          transform: TranslateY(0%) !important;
          padding: 0 30px 20px;
          margin: 0 !important;
          font-size: 0.9em;
          .button {
            text-transform: uppercase;
          }

        }
        .background {
          background-position: center center;
        }
        .content {
          left: 40px !important;
          width: 100%;
          max-width: 1900px;
          padding: 0 20px;
          position: relative;
          margin-bottom: 60px;
          /* reset styles from tg-base */
          top: auto !important;
          bottom: auto !important;
          & h1 {
            font-size: 35px;
          }
          h1,
          h2,
          h3,
          h4 {
            margin: 0;
            line-height: normal;
          }
    
          @media (max-width: 992px) {
            left: 20px !important;
          }
        }
      }

      @media (max-width: 992px)
      {
        min-height: unset;
      }
    }    
  }
}
