body {
  --gameplay-topbar-height: 0px;
  --gameplay-header-height: 56px;
  --gameplay-bottombar-height: 0px;
  --gameplay-frame-darkmode-bgcolor: var(--black);
  --gameplay-popunder-overlay-bgcolor: var(--overlay);
  --gameplay-header-bgcolor: var(--primary-gradient);
  &.is-desktop {
    --gameplay-header-height: 60px;
    --gameplay-bottombar-height: 75px;
  }
  &.jurisdiction-sga {
    --gameplay-topbar-height: 30px;
  }
}

.PlayContainer {
  .button.icon {
  }

  .GamePlay {
    .GamePlayHeader {
      background: url('../images/logo.svg'), var(--gameplay-header-bgcolor);
      box-shadow: var(--box-shadow);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 60px;
      color: var(--black);

      &__hamburger {
        background-color: var(--secondary-btn-color);
        box-shadow: var(--box-shadow);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin: 0 10px 0px 5px !important;

        img {
          margin-top: 5px;
          height: 16px !important;
          @media (max-width: 992px) {
            height: 15px;
          }
        }

        &:hover {
          background: var(--gray);
        }
      }

      &__back {
        button {
          box-shadow: var(--box-shadow);
          background: var(--white);
          color: var(--black);
          border-radius: 50%;

          i {
            font-size: 28px;
          }
        }

        span {
          font-size: 16px;
        }
      }

      &__menu,
      &__menu-close {
        box-shadow: var(--box-shadow);
        background: var(--white);
        color: var(--black);
        border-radius: 50%;
        margin-left: 4px;
      }

      &__quick-deposit-desktop {
        height: auto;
      }

      &__quick-deposit {
        box-shadow: var(--box-shadow);
        background-color: var(--primary-btn-color);
        border-radius: 1.5em;
        form {
          .QuickAmounts .button .show {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .tg-select .Select select {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: calc(20px + var(--trailing-lane-width));
          }

          .tg-input .Input {
            .Input__status-line {
              display: none;
            }

            input {
              height: 37px;
            }
          }

          a {
            font-size: 18px;
            line-height: 40px;
            text-transform: uppercase;

            i {
              display: none;
            }
          }
        }

        .QuickDeposit {
          &__account-select {
            width: auto;
          }

          form > * {
            margin: auto;
          }
        }

        .QuickDepositMobile {
          display: flex;
          background: var(--white);
          border-radius: 50%;
          height: auto;

          i {
            color: var(--black);
          }
        }
      }

      &__user {
        .button {
          display: none;
        }
      }

      &__name {
        display: flex;
        margin-right: 8px;
      }

      .MembershipActions {
        .button {
          &:not(.user-balance) {
            font-size: 1.3em;
            padding: 0 2em;
            height: 40px;
            line-height: 40px;
            margin: 0;
          }
        }
      }

      @media (max-width: 992px) {
        .MembershipActions {
          display: none;
        }
      }
    }

    .PopUnder--closed {
      .GameDetails {
        height: 70px;
        margin-top: 5px;
      }
    }

    .PopUnder--open {
      .GameDetails {
        height: 100%;
        margin-top: 15px;
      }
    }

    .GameDetails {
      background: var(--light-blue);
      color: var(--white);
      padding: 5px 10px 5px 10px;

      &__fav {
        background: var(--white);
        color: lightgray;

        i {
          font-size: 25px;
        }
      }

      &__quick-actions {
        button {
          background: var(--white);
          box-shadow: var(--box-shadow);
          color: lightgray;
          border-radius: 50%;

          i {
            font-size: 25px;
          }
        }
      }

      &__categories-nav {
        margin-bottom: 10px;

        .CasinoCategoryMenu {
          margin-top: 5px;
          color: var(--black);
          .casino-search {
            background-color: var(--white);
            box-shadow: var(--box-shadow);
            color: var(--black);
            margin-left: 0;
            margin-right: 15px;

            i {
              width: 50px !important;
              height: 50px !important;
              background-color: var(--white);
              color: var(--black);
              font-size: 30px;
              margin-left: 0 !important;
            }
          }

          .active {
            border-radius: 300px;
            height: 50px;

            i {
              margin-left: 5px;
              box-shadow: none;
            }

            input {
              color: var(--black);
              border-radius: 18px;
              padding-left: 50px;

              &::placeholder {
                font-size: 15px;
                color: gray;
              }
            }
          }

          .categories-menu {
            display: contents;

            &::after {
              background: none !important;
            }

            .category-item {
              background-color: var(--white);
              color: var(--black);
              font-size: 15px;
              border-radius: 20px;
              width: auto;
              height: 40px;
              line-height: 22px;
              text-transform: lowercase;

              &:hover {
                background-color: var(--secondary-btn-background-hover);
              }

              &.active {
                background-color: var(--gray);
              }
            }
          }
        }
      }

      &__category {
        .GameGrid {
          .slider .last-slide a {
            &:last-child {
              color: var(--white);
            }
          }

          @media (min-width: 993px)
          {
            .track {
              transform : translateX(-12px) !important;
            }
          }

          .slider {
            height: 100%;
            .list {
              margin-left: 50px;
              .thumbnail {
                width: auto;
                height: auto;
              }
            }
          }

          @media (min-width: 1190px) {
            .slider {
              .list {
                margin-left: 62px;
              }
            }
          }
        }

        .Game {
          width: 17vw !important;
          background: none;
          box-shadow: none;

          img {
            border-radius: var(--border-radius);
          }

          .game-info {
            position: absolute;

            .title {
              font-size: 17px;
              color: var(--white);
            }

            .provider {
              font-size: 10px;
              color: var(--white);
            }
          }

          @media (max-width: 992px) {
            width: 25vw !important;
          }

          @media (max-width: 500px) {
            width: 70vw !important;
            .game-info {
              position: relative;
              margin: 0;
              padding-left: 0;

              .title {
                font-size: 17px;
                color: var(--black);
              }

              .provider {
                font-size: 10px;
                color: var(--light-blue);
              }
            }
          }
        }

        @media (max-width: 992px) {
          margin-bottom: 50px;
          .NoGamesMessage {
            color: darkgray;
            padding: 30px;
          }

          .GameGrid {
            .slider {
              .list {
                margin: 0 3px 0 3px;
              }
            }
          }

          .Game {
            background: none;
            box-shadow: none;

            img {
              border-radius: var(--border-radius);
            }

            .provider {
              color: var(--light-blue);
            }
          }
        }
      }

      @media (max-width: 992px) {
        background-color: var(--main-background);
        color: var(--black);
        height: auto !important;
        margin: 0 !important;
        margin: 0;

        .CasinoCategoryMenu {
          padding: 0;

          .active {
            i {
              left: 7px;
            }
          }

          .casino-search {
            margin-left: 10px;
          }

          .categories-menu {
            display: none;
          }
        }

        &__info {
          line-height: 40px;
          h1 {
            color: var(--black);
            font-weight: normal;
          }
        }
      }
    }

    .GameFrame {
      &__footer {
        display: none;
      }
    }
  }
}
