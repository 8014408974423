.SessionSummary {
  .Summary {
    .date {
      color: var(--white) !important;
      font-weight: 400;
    }

    .info {
      font-weight: bold;

      span:nth-child(2) {
        color: var(--white) !important;
        font-weight: 400;
      }
    }
  }

  .ResponsibleGaming {
    a {
      color: var(--white) !important;
    }
  }
}
