@import './colors.css';
@import './variables.css';

.PlayContainer,
.CasinoContainer {
  .CasinoCategoryMenu {
    align-items: center;
    color: var(--black);

    &:before {
      display: none;
    }

    /* Hide categories when searching */
    .casino-search.active + .categories-menu {
      display: none;
    }

    .casino-search {
      background: var(--primary-btn-gradient);
      box-shadow: var(--box-shadow);
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      transition: none;

      .search-input {
        display: flex;
        width: 100%;

        .Input {
          width: 100%;
          .Input__status-line {
            display: none;
          }
        }
      }

      i {
        position: absolute;
        color: var(--black);
        font-size: 30px;
        width: 50px !important;
        height: 50px !important;
        left: 10;
        padding: 10px;
        border-radius: 50%;
        background: var(--secondary-btn-color);
        box-shadow: var(--box-shadow);
        z-index: 5;

        &:hover {
          background: var(--secondary-btn-background-hover);
        }
      }

      i.icon-close {
        &:before {
          content: '';
          background-image: url('../images/search-close.svg');
          width: 15px;
          height: 15px;
        }
      }

      &.active {
        background: var(--white);
        box-shadow: none;
        border-radius: var(--border-radius);
        padding-left: 5px;
        color: var(--black);
        height: 60px;
        border-radius: 30px;
        width: 520px;

        i {
          box-shadow: none;
        }

        input {
          color: var(--black);
          border-radius: 18px;
          padding-left: 60px;

          &::placeholder {
            font-size: 20px;
            color: gray;
          }
        }
      }
    }

    .categories-menu {
      height: 70px;
      margin-left: 10px;
      .category-item {
        background-color: var(--white);
        box-shadow: var(--box-shadow);
        border-radius: 50px;
        line-height: 20px;
        color: var(--black);
        text-transform: lowercase;
        height: auto;
        padding: 10px 20px;

        &:hover {
          background-color: var(---secondary-btn-background-hover);
        }

        &.active {
          background-color: var(--secondary-btn-background);
          font-weight: normal;
          color: var(--black);
        }

        a {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 992px) {
      margin-right: 0;

      .casino-search {
        margin-left: -12px;
      }
    }
  }
}
