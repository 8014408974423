.ContentPreviewContainer {
  form {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: unset;
    }
  }
}
