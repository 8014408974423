.rg-gaming-content {
  .popup-content {
    background: var(--prmary-colori) !important;
    text-align: center;

    > div {
      min-height: unset;
      width: 100% !important;
    }

    .button-wrapper {
      display: block;
    }
  }
}
