@import './variables.css';
@import './colors.css';

.casino-promotion {
  background-color: #4fc5ff;

  .casino-promotion-image,
  .casino-promotion-video video {
    box-shadow: var(--box-shadow);
    border-radius: 6px;
  }

  .stats-wrapper,
  .summary p {
    font-weight: 600;
  }

  .summary {
    img {
      height: 100px;
    }
  }

  .actions.button.primary {
    font-size: 18px;
  }

  .casino-promotion-bg {
    opacity: 1;
    &::before {
      background: none;
    }

    &::after {
      background: none;
    }
  }

  @media (max-width: 992px) {
    .stats-wrapper {
      display: none;
    }

    .summary p,
    a.actions {
      margin: 0;
      align-self: center;
    }

    .summary {
      order: 1;
      width: 100%;
      margin-bottom: 10px;
    }

    .casino-promotion-content {
      flex-direction: column-reverse;
    }
  }
}
