@import './colors.css';
@import './variables.css';

button,
.primary-button,
.secondary-button,
.button,
.bonus-code-form,
.Accordion .button,
.responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button,
.responsiblegaming-signup .button {
  border-radius: 1.5em 1.5em 1.5em 1.5em;
  text-transform: lowercase;
  color: var(--white);
  font-weight: normal !important;

  &.primary,
  .primary-button {
    background: var(--primary-btn-color);
    box-shadow: var(--box-shadow);
    color: var(--white);
    transition: none;

    &:hover {
      background: var(--primary-btn-background-hover);
      color: var(--white);
    }
  }

  &.tertiary,
  .tertiary-button {
    background: var(--white);
    box-shadow: var(--box-shadow);
    color: var(--black);
    transition: none;

    &:hover {
      background: var(--gray);
      color: var(--black);
    }
  }

  &.secondary,
  .secondary-button {
    background: var(--secondary-btn-color);
    box-shadow: var(--box-shadow);
    color: var(--black);
    transition: none;

    &:hover {
      background: var(--secondary-btn-background-hover);
      color: var(--black);
    }
  }

  &.selected {
    background: var(--primary-btn-background-hover) !important;
  }

  &.loading {
    svg {
      circle {
        stroke: var(--white) !important;
      }
    }
  }
}
