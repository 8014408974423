@import './colors.css';
@import './variables.css';

.OffCanvas {
  .canvas-overlay {
    background: var(--overlay);
  }

  .canvas-content {
    background-image: linear-gradient(to bottom, #5bd2fe, #2aa5ff);

    @media (max-width: 992px) {
      padding: 5px 20px 100px;
    }

    form {
      .tg-input {
        .Input {
          &__label {
            color: var(--black);
          }
        }

        &__status-text {
          color: var(--black);
        }
      }
    }

    header {
      text-align: center;
      margin-bottom: 25px;

      h1 {
        color: var(--black);
        font-weight: normal;
        text-transform: uppercase;

        margin: 0;
      }

      p {
        color: var(--black);
        font-weight: normal;
        padding: 5px 0 5px 0;
      }

      @media (max-width: 992px) {
        margin-right: 10px;
      }
    }

    .canvas-header {
      .sidebar-title {
        display: flex;
      }

      .canvas-logo {
        margin-top: 30px;
        height: 100px;
      }

      .canvas-close,
      .canvas-back {
        margin-top: -60px;
      }

      .canvas-header-content {
        width: 100px;
        display: block;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100px;
        }
      }
    }

    .messages-list {
      .messages-list-item {
        color: var(--black);

        h3,
        span {
          color: var(--black);
        }

        &:hover {
          background-color: var(--secondary-color-hover);
        }
      }
    }

    .canvas-close,
    .canvas-back {
      padding: 7px;
      border-radius: 50px;
      box-shadow: var(--box-shadow);
      background: var(--white);
      height: 40px;
      width: 40px;
      position: relative;

      &:empty {
        visibility: hidden;
      }

      a {
        width: 18px;
        position: absolute;
        right: 11px;
        top: 11px;
      }

      img.back-sidebar-icon {
        position: absolute;
        top: 0;
        left: 0;
      }

      img.exit-sidebar-icon {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
      }

      &:hover {
        background: var(--gray);
      }

      @media (max-width: 992px) {
        a {
          width: 18px;
          position: absolute;
          right: 11px;
          top: 0px;
        }
      }
    }

    .canvas-close {
      a {
        width: 40px;
        height:40px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        padding: 10px;
      }
    }

    .canvas-back {
      a {
        width: 30px;
        position: absolute;
        right: 6px;
        top: 5px;
      }

      @media (max-width: 992px) {
        a {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .DashboardContainer.OffCanvas.OffCanvas--direction-right {
    height: 100%;
  }
}
