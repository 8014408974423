@import './colors.css';
@import './variables.css';

.verify-account-wrapper {
  .kyc-error,
  .kyc-success {
    border-radius: var(--border-radius);
    margin-bottom: 10px;
    text-align: center;
    background: var(--secondary-color);
  }

  .verify-profile {
    background: var(--secondary-color);
    padding: 10px;
    border-radius: var(--border-radius);

    .error-message {
      font-weight: 500;
      font-style: normal;
      text-align: center;
    }

    .select-type-input {
      width: 100% !important;
    }

    .upload-description {
      text-align: left;
    }

    .verify-profile-instruction-wrapper {
      padding-bottom: 0;
    }

    .kyc-upload-wrapper {
      flex-flow: column;
      text-align: center;

      .default-upload {
        display: none;
      }

      .custom-upload {
        border-radius: var(--border-radius);
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;

        .selected-file {
          height: 42px;
          background: var(--tertiary-color);
          font-weight: 500;
          width: 100%;
          color: #5aa2bb;
          display: inline-block;
          padding: 10px 0 10px 0 !important;
          border-radius: 0 0 var(--border-radius) var(--border-radius);
        }

        .choose-file {
          background: var(--tertiary-color) !important;
          border-radius: var(--border-radius) var(--border-radius) 0 0;
          width: 100%;
          font-weight: 800 !important;
          background: none;
        }
      }

      .kyc-upload-button {
        margin-bottom: 20px;
      }
    }
  }

  .tg-list {
    .row {
      margin-top: 5px;
      border-radius: var(--border-radius);
      padding: 10px;
      background: var(--secondary-color);
    }
  }
}
