@import './colors.css';
@import './variables.css';

.ErrorBoundary {
  padding: 0;

  .error-message-container {
    text-align: center;
    border-radius: var(--border-radius);

    h1,
    p {
      color: var(--white);
    }
  }
}
