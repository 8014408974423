.tg-list {
  .row {
    &.active {
      .detail {
        background: var(--secondary-color);
        justify-content: space-evenly;

        &:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid var(--secondary-color);
        }
      }
    }

    .info {
      &:before {
        background: #5bc2ff;
      }
    }
  }
}
