/* Put all brand specific css and logos/backgrounds/icons in this skin folder. */

@font-face {
  font-family: Baloo;
  src: url('/skin/fonts/Baloo-Regular.ttf') format('truetype');
}

.OffCanvas,
.WelcomeBonus,
.content h3,
body,
.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
nav.categories a,
.casino-categories-item,
.casino-categories-item-total,
.tg-input .Input input,
select,
.Promotion .content h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Baloo', 'Fira Sans', sans-serif;
}

button,
.primary-button,
.secondary-button,
.button,
.bonus-code-form,
.Accordion .button,
.responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button,
.responsiblegaming-signup .button {
  border-radius: 1.5em 1.5em 1.5em 1.5em;
  text-transform: lowercase;
  color: #ffffff;
  font-weight: normal !important;
}

button.primary,
  button .primary-button,
  .primary-button.primary,
  .primary-button .primary-button,
  .secondary-button.primary,
  .secondary-button .primary-button,
  .button.primary,
  .button .primary-button,
  .bonus-code-form.primary,
  .bonus-code-form .primary-button,
  .Accordion .button.primary,
  .Accordion .button .primary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.primary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .primary-button,
  .responsiblegaming-signup .button.primary,
  .responsiblegaming-signup .button .primary-button {
    background: #42d19d;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #ffffff;
    transition: none;
  }

button.primary:hover, button .primary-button:hover, .primary-button.primary:hover, .primary-button .primary-button:hover, .secondary-button.primary:hover, .secondary-button .primary-button:hover, .button.primary:hover, .button .primary-button:hover, .bonus-code-form.primary:hover, .bonus-code-form .primary-button:hover, .Accordion .button.primary:hover, .Accordion .button .primary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.primary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .primary-button:hover, .responsiblegaming-signup .button.primary:hover, .responsiblegaming-signup .button .primary-button:hover {
      background: #3bb98b;
      color: #ffffff;
    }

button.tertiary,
  button .tertiary-button,
  .primary-button.tertiary,
  .primary-button .tertiary-button,
  .secondary-button.tertiary,
  .secondary-button .tertiary-button,
  .button.tertiary,
  .button .tertiary-button,
  .bonus-code-form.tertiary,
  .bonus-code-form .tertiary-button,
  .Accordion .button.tertiary,
  .Accordion .button .tertiary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.tertiary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .tertiary-button,
  .responsiblegaming-signup .button.tertiary,
  .responsiblegaming-signup .button .tertiary-button {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #414141;
    transition: none;
  }

button.tertiary:hover, button .tertiary-button:hover, .primary-button.tertiary:hover, .primary-button .tertiary-button:hover, .secondary-button.tertiary:hover, .secondary-button .tertiary-button:hover, .button.tertiary:hover, .button .tertiary-button:hover, .bonus-code-form.tertiary:hover, .bonus-code-form .tertiary-button:hover, .Accordion .button.tertiary:hover, .Accordion .button .tertiary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.tertiary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .tertiary-button:hover, .responsiblegaming-signup .button.tertiary:hover, .responsiblegaming-signup .button .tertiary-button:hover {
      background: #eeeeee;
      color: #414141;
    }

button.secondary,
  button .secondary-button,
  .primary-button.secondary,
  .primary-button .secondary-button,
  .secondary-button.secondary,
  .secondary-button .secondary-button,
  .button.secondary,
  .button .secondary-button,
  .bonus-code-form.secondary,
  .bonus-code-form .secondary-button,
  .Accordion .button.secondary,
  .Accordion .button .secondary-button,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.secondary,
  .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .secondary-button,
  .responsiblegaming-signup .button.secondary,
  .responsiblegaming-signup .button .secondary-button {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #414141;
    transition: none;
  }

button.secondary:hover, button .secondary-button:hover, .primary-button.secondary:hover, .primary-button .secondary-button:hover, .secondary-button.secondary:hover, .secondary-button .secondary-button:hover, .button.secondary:hover, .button .secondary-button:hover, .bonus-code-form.secondary:hover, .bonus-code-form .secondary-button:hover, .Accordion .button.secondary:hover, .Accordion .button .secondary-button:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.secondary:hover, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button .secondary-button:hover, .responsiblegaming-signup .button.secondary:hover, .responsiblegaming-signup .button .secondary-button:hover {
      background: linear-gradient(
    90deg,
    #efefef 20%,
    #eeeeee
  );
      color: #414141;
    }

button.selected, .primary-button.selected, .secondary-button.selected, .button.selected, .bonus-code-form.selected, .Accordion .button.selected, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.selected, .responsiblegaming-signup .button.selected {
    background: #3bb98b !important;
  }

button.loading svg circle, .primary-button.loading svg circle, .secondary-button.loading svg circle, .button.loading svg circle, .bonus-code-form.loading svg circle, .Accordion .button.loading svg circle, .responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button.loading svg circle, .responsiblegaming-signup .button.loading svg circle {
        stroke: #ffffff !important;
      }

input,
select {
  border: none;
  border-radius: 4px;
  background: #ffffff;
  color: black;
}

input::-webkit-input-placeholder, select::-webkit-input-placeholder {
    color: #474747;
  }

input::placeholder, select::placeholder {
    color: #474747;
  }

input[type='file'] {
  background: none;
  color: inherit;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  min-width: 26px;
  height: 26px;
  background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
  border-radius: 4px;
  border: 1px solid #2ca6ff;
  margin-right: 12px;
}

input[type='checkbox']:checked {
  background-image: url('../images/check.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1.5em;
  background-color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
}

input[type='radio'] {
  background: #ffffff;
  border: 2px solid #2ca6ff;
}

input[type='radio']:checked {
  border: 5px solid #2ca6ff;
  background: #ffffff;
}

.Input {
  color: #414141;
}

.tg-select .Select .Select__status-line {
    display: none;
  }

.tg-select .Select .Input__leading-lane,
  .tg-select .Select .Input__trailing-lane,
  .tg-select .Select .Select__leading-lane,
  .tg-select .Select .Select__trailing-lane {
    -webkit-transform: translateY(-65%);
            transform: translateY(-65%);
  }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #ffffff;
}

.Popup__content h1,
.Popup__content h2,
.Popup__content h3,
.Popup__content h4,
.Popup__content h5,
.Popup__content h6,
.Popup__content .h1,
.Popup__content .h2,
.Popup__content .h3,
.Popup__content .h4,
.Popup__content .h5,
.Popup__content .h6 {
  color: #414141;
}

body.is-offcanvas-open #fc_frame:not(.fc-open) {
      display: none;
    }

#fc_frame:not(.fc-open) {
  display: initial;
}

@media (max-width: 768px) {

#fc_frame:not(.fc-open) {
    display: none
}
  }

@media (max-width: 768px) {

#launcher {
    display: none
}
  }

@-webkit-keyframes mermaid-notify {
  from {
    right: 0px;
  }
  to {
    right: -500px;
  }
}

@keyframes mermaid-notify {
  from {
    right: 0px;
  }
  to {
    right: -500px;
  }
}

@-webkit-keyframes mermaid-side {
  from {
    right: -400px
  }
  to {
    right: -70px;
  }
}

@keyframes mermaid-side {
  from {
    right: -400px
  }
  to {
    right: -70px;
  }
}

@-webkit-keyframes chat-bubble {
  from {
    -webkit-transform: TranslateY(-230%) Scale(0%);
            transform: TranslateY(-230%) Scale(0%);
  }
  to {
    -webkit-transform: TranslateY(-230%) Scale(100%);
            transform: TranslateY(-230%) Scale(100%);
  }
}

@keyframes chat-bubble {
  from {
    -webkit-transform: TranslateY(-230%) Scale(0%);
            transform: TranslateY(-230%) Scale(0%);
  }
  to {
    -webkit-transform: TranslateY(-230%) Scale(100%);
            transform: TranslateY(-230%) Scale(100%);
  }
}

@-webkit-keyframes mermaid-bottom {
  from {
    bottom: -250px;
  }
  to {
    bottom: -20px;
  }
}

@keyframes mermaid-bottom {
  from {
    bottom: -250px;
  }
  to {
    bottom: -20px;
  }
}

@-webkit-keyframes bubble-first {
  from {
    bottom: -400px
  }
  to {
    bottom: 140px;
  }
}

@keyframes bubble-first {
  from {
    bottom: -400px
  }
  to {
    bottom: 140px;
  }
}

@-webkit-keyframes bubble-second {
  from {
    bottom: -400px
  }
  to {
    bottom: 300px;
  }
}

@keyframes bubble-second {
  from {
    bottom: -400px
  }
  to {
    bottom: 300px;
  }
}

@-webkit-keyframes bubble-third {
  from {
    bottom: -400px
  }
  to {
    bottom: 200px;
  }
}

@keyframes bubble-third {
  from {
    bottom: -400px
  }
  to {
    bottom: 200px;
  }
}

@-webkit-keyframes shine {
  from {
    -webkit-transform: translateX(-160px) skewX(15deg);
            transform: translateX(-160px) skewX(15deg);
  }

  to {
    -webkit-transform: translateX(2000px) skewX(15deg);
            transform: translateX(2000px) skewX(15deg);
  }
}

@keyframes shine {
  from {
    -webkit-transform: translateX(-160px) skewX(15deg);
            transform: translateX(-160px) skewX(15deg);
  }

  to {
    -webkit-transform: translateX(2000px) skewX(15deg);
            transform: translateX(2000px) skewX(15deg);
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('/skin/icomoon/icomoon.eot?gl78ba');
  src: url('/skin/icomoon/icomoon.eot?gl78ba#iefix') format('embedded-opentype'),
    url('/skin/icomoon/icomoon.ttf?gl78ba') format('truetype'),
    url('/skin/icomoon/icomoon.woff?gl78ba') format('woff'),
    url('/skin/icomoon/icomoon.svg?gl78ba#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-flag:before {
  content: '\e900';
}

.icon-lock:before {
  content: '\e901';
}

.icon-book:before {
  content: '\e902';
}

.icon-times-circle:before {
  content: '\e903';
}

.icon-list:before {
  content: '\e904';
}

.icon-key:before {
  content: '\e905';
}

.icon-user:before {
  content: '\e906';
}

.icon-check-square:before {
  content: '\e907';
}

.icon-cog:before {
  content: '\e908';
}

.icon-heart-o:before {
  content: '\e909';
}

.icon-chevron-left:before {
  content: '\e90a';
}

.icon-avatar:before {
  content: '\e90b';
}

.icon-live-casino:before {
  content: '\e90c';
}

.icon-sports:before {
  content: '\e90d';
}

.icon-app:before {
  content: '\e90e';
}

.icon-angle-left:before {
  content: '\e90f';
}

.icon-angle-right:before {
  content: '\e910';
}

.icon-caret-down:before {
  content: '\e911';
}

.icon-check:before {
  content: '\e912';
}

.icon-promotions:before {
  content: '\e913';
}

.icon-chevron-right:before {
  content: '\e914';
}

.icon-headphones:before {
  content: '\e915';
}

.icon-heart:before {
  content: '\e916';
}

.icon-times:before {
  content: '\e917';
}

.icon-coins:before {
  content: '\e918';
}

.icon-cogs:before {
  content: '\e919';
}

.icon-dollar-sign:before {
  content: '\e91a';
}

.icon-envelope:before {
  content: '\e91b';
}

.icon-pencil:before {
  content: '\e91d';
}

.icon-trash:before {
  content: '\e91e';
}

.icon-casino:before {
  content: '\e91f';
}

.icon-chevron-down:before {
  content: '\e920';
}

.icon-exclamation-triangle:before {
  content: '\e922';
}

.icon-angle-down:before {
  content: '\e923';
}

.icon-phone:before {
  content: '\e924';
}

.icon-external-link:before {
  content: '\e925';
}

.icon-plus:before {
  content: '\e926';
}

.icon-minus:before {
  content: '\e927';
}

.icon-info-circle:before {
  content: '\e928';
}

.icon-history:before {
  content: '\e929';
}

.icon-question-circle:before {
  content: '\e92a';
}

.icon-stop-circle:before {
  content: '\e92b';
}

.icon-chevron-circle-left:before {
  content: '\e92c';
}

.icon-sign-in:before {
  content: '\e92d';
}

.icon-ticket:before {
  content: '\e92e';
}

.icon-user-circle:before {
  content: '\e92f';
}

.icon-youtube:before {
  content: '\e930';
}

.icon-twitter:before {
  content: '\e931';
}

.icon-facebook:before {
  content: '\e932';
}

.icon-instagram:before {
  content: '\e933';
}

.icon-check-circle:before {
  content: '\e934';
}

.icon-fist:before {
  content: '\e935';
}

.icon-gameplay-menu:before {
  content: '\e936';
}

.icon-cc-visa:before {
  content: '\e937';
}

.icon-stopwatch:before {
  content: '\e938';
}

.icon-lightbulb-off:before {
  content: '\e939';
}

.icon-expand:before {
  content: '\e93b';
}

.icon-cc-mastercard:before {
  content: '\e93c';
}

.icon-virtual-sports:before {
  content: '\e93d';
}

.icon-compress:before {
  content: '\e93e';
}

.icon-home:before {
  content: '\e93f';
}

.icon-ellipsis-v:before {
  content: '\e940';
}

.icon-bars:before {
  content: '\e941';
}

.icon-angle-up:before {
  content: '\e942';
}

.icon-check-circle-o:before {
  content: '\e943';
}

.icon-hashtag:before {
  content: '\e944';
}

.icon-close:before {
  content: '\e945';
}

.icon-clock:before {
  content: '\e946';
}

.icon-snowflake:before {
  content: '\e947';
}

.icon-users:before {
  content: '\e948';
}

.icon-caret-up:before {
  content: '\e949';
}

.icon-eye:before {
  content: '\e94a';
}

.icon-eye-slash:before {
  content: '\e94b';
}

.icon-chevron-circle-right:before {
  content: '\e94c';
}

.icon-lightbulb-on:before {
  content: '\e94e';
}

.icon-search:before {
  content: '\e94f';
}

.icon-refresh:before {
  content: '\e984';
}

.Footer {
  position: relative;
  background-color: #ffffff;
  color: #7e7e7e;
}

.Footer h3 {
    text-transform: none !important;
    color: #434343;
    font-weight: normal !important;
  }

.Footer .topSection {
    background-color: #ffffff;
    color: #434343;
  }

.Footer .topSection a {
      margin: 0 15px !important;
      font-size: 1.7em !important;
      font-weight: 500;
      text-transform: lowercase;
    }

.Footer .topSection .LanguageSelector {
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      margin-bottom: 30px;
      margin-top: 20px;
      font-weight: normal;
    }

.Footer .topSection .LanguageSelector__label {
        padding-left: 0;
        justify-content: space-between;
        text-transform: lowercase;
      }

.Footer .topSection .LanguageSelector__label img {
          width: 30px;
          height: 30px;
          margin-left: 7px;
        }

.Footer .topSection .LanguageSelector__label .select-icon {
          margin-right: 10px;
        }

.Footer .topSection .container {
      margin-bottom: 10px;
      padding: 0 10px;
    }

.Footer .footer-logo {
    background-image: url('../images/logo.png');
    margin: 15px 0px;
    background-size: 70px 70px;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
  }

.Footer .footer-logo img {
      display: none;
      height: 70px !important;
    }

.Footer .trademark {
    line-height: 15px;
  }

.Footer .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 10vw;
  }

.Footer .content-container .three {
      order: -1;
    }

.Footer div.licence {
    flex-wrap: wrap;
  }

.Footer div.licence img {
      height: 44px !important;
      width: auto !important;
    }

.Footer .licence a {
    -webkit-filter: brightness(2);
            filter: brightness(2);
    margin: 10px;
  }

.header-wrapper {
  background: linear-gradient(to right, #5bd2fe 30%, #2aa5ff 70%);
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.header-wrapper .Header__background.Header__background--visible {
    display: none;
  }

.header-wrapper .Header::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 160px;
      height: 100%;
      -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
              clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      pointer-events: none;
    }

.header-wrapper .Header__logo {
    margin: 5px 0 0 20px;
  }

.header-wrapper .Header__logo img {
      height: 58px;
      margin-top: 5xpx;
      width: auto;
    }

.header-wrapper .MembershipActions .button:not(.user-balance) {
        font-size: 1.3em;
        text-transform: uppercase;
        padding: 0 2em;
        height: 40px;
        line-height: 40px;
        margin: 0;
      }

.header-wrapper .MembershipActions .button:not(.user-balance):first-child {
          margin-right: 10px;
        }

@media (max-width: 992px) {

.header-wrapper .MembershipActions .button:not(.user-balance) {
          padding: 0 1em;
          font-size: 1.2em
      }
        }

.header-wrapper .MembershipActions .user-balance {
      font-size: 1.3em;
      flex-flow: row-reverse;
      padding-right: 5px !important;
      padding-left: 0 !important;
      margin: 0;
    }

.header-wrapper .MembershipActions .user-balance .icon-eye-opened,
      .header-wrapper .MembershipActions .user-balance .icon-eye-closed,
      .header-wrapper .MembershipActions .user-balance .dots {
        display: none;
      }

.header-wrapper .MembershipActions .user-balance .balance-hide {
        height: auto;
        opacity: 1;
      }

.header-wrapper .MembershipActions .user-balance .icon-plus {
        position: relative;
        width: 31px;
        height: 31px;
        font-size: 15px;
        background: #37b588;
        border-radius: 50%;
        margin-left: 5px;
        line-height: 25px;
      }

.header-wrapper .MembershipActions .user-balance .icon-plus:before {
          position: absolute;
        }

.header-wrapper .Header__support button,
  .header-wrapper .hamburger-icon {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

.header-wrapper .Header__support span {
      display: none;
    }

.header-wrapper .Header__support a {
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

.header-wrapper .Header__topnav {
    height: 100%;
    display: flex;
    align-items: center;
  }

.header-wrapper .Header__topnav .top-nav-menu-item {
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      font-size: 20px !important;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 60px;
      position: relative;
      padding: 0 8px;
      margin-left: 5px;
    }

.header-wrapper .Header__topnav .top-nav-menu-item:first-child {
        margin-left: 18px;
      }

.header-wrapper .Header__topnav .top-nav-menu-item.active:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 100%;
          background: #ffffff;
        }

.header-wrapper .user-balance {
    height: 40px;
  }

.header-wrapper > .Header .Header__support {
    margin-right: 0;
  }

.header-wrapper .Header__support a {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 15px !important;
    padding: 0;
    background: #ffffff;
    display: block;
  }

.header-wrapper .Header__support a i:before {
        content: '';
        width: 30px;
        height: 31px;
        background-image: url('../images/support-small.svg');
        position: absolute;
        background-size: 28px 28px;
        background-repeat: no-repeat;
        top: 5px;
        left: 5px;
      }

.header-wrapper .Header__support a:hover {
      background: lightgray;
      opacity: 1 !important;
    }

.header-wrapper .hamburger-icon {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-left: 20px !important;
    margin-right: 10px !important;
  }

.header-wrapper .hamburger-icon img {
      height: 16px !important;
    }

.header-wrapper .hamburger-icon:hover {
      background: lightgray;
    }

.OffCanvas .canvas-overlay {
    background: rgba(0, 0, 0, 0.8);
  }

.OffCanvas .canvas-content {
    background-image: linear-gradient(to bottom, #5bd2fe, #2aa5ff);
  }

@media (max-width: 992px) {

.OffCanvas .canvas-content {
      padding: 5px 20px 100px
  }
    }

.OffCanvas .canvas-content form .tg-input .Input__label {
            color: #414141;
          }

.OffCanvas .canvas-content form .tg-input__status-text {
          color: #414141;
        }

.OffCanvas .canvas-content header {
      text-align: center;
      margin-bottom: 25px;
    }

.OffCanvas .canvas-content header h1 {
        color: #414141;
        font-weight: normal;
        text-transform: uppercase;

        margin: 0;
      }

.OffCanvas .canvas-content header p {
        color: #414141;
        font-weight: normal;
        padding: 5px 0 5px 0;
      }

@media (max-width: 992px) {

.OffCanvas .canvas-content header {
        margin-right: 10px
    }
      }

.OffCanvas .canvas-content .canvas-header .sidebar-title {
        display: flex;
      }

.OffCanvas .canvas-content .canvas-header .canvas-logo {
        margin-top: 30px;
        height: 100px;
      }

.OffCanvas .canvas-content .canvas-header .canvas-close,
      .OffCanvas .canvas-content .canvas-header .canvas-back {
        margin-top: -60px;
      }

.OffCanvas .canvas-content .canvas-header .canvas-header-content {
        width: 100px;
        display: block;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

.OffCanvas .canvas-content .canvas-header .canvas-header-content img {
          width: 100px;
        }

.OffCanvas .canvas-content .messages-list .messages-list-item {
        color: #414141;
      }

.OffCanvas .canvas-content .messages-list .messages-list-item h3,
        .OffCanvas .canvas-content .messages-list .messages-list-item span {
          color: #414141;
        }

.OffCanvas .canvas-content .messages-list .messages-list-item:hover {
          background-color: #d3d3d3;
        }

.OffCanvas .canvas-content .canvas-close,
    .OffCanvas .canvas-content .canvas-back {
      padding: 7px;
      border-radius: 50px;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      background: #ffffff;
      height: 40px;
      width: 40px;
      position: relative;
    }

.OffCanvas .canvas-content .canvas-close:empty, .OffCanvas .canvas-content .canvas-back:empty {
        visibility: hidden;
      }

.OffCanvas .canvas-content .canvas-close a, .OffCanvas .canvas-content .canvas-back a {
        width: 18px;
        position: absolute;
        right: 11px;
        top: 11px;
      }

.OffCanvas .canvas-content .canvas-close img.back-sidebar-icon, .OffCanvas .canvas-content .canvas-back img.back-sidebar-icon {
        position: absolute;
        top: 0;
        left: 0;
      }

.OffCanvas .canvas-content .canvas-close img.exit-sidebar-icon, .OffCanvas .canvas-content .canvas-back img.exit-sidebar-icon {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
      }

.OffCanvas .canvas-content .canvas-close:hover, .OffCanvas .canvas-content .canvas-back:hover {
        background: #eeeeee;
      }

@media (max-width: 992px) {
        .OffCanvas .canvas-content .canvas-close a, .OffCanvas .canvas-content .canvas-back a {
          width: 18px;
          position: absolute;
          right: 11px;
          top: 0px;
        }
      }

.OffCanvas .canvas-content .canvas-close a {
        width: 40px;
        height:40px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        padding: 10px;
      }

.OffCanvas .canvas-content .canvas-back a {
        width: 30px;
        position: absolute;
        right: 6px;
        top: 5px;
      }

@media (max-width: 992px) {
        .OffCanvas .canvas-content .canvas-back a {
          top: 0;
        }
      }

@media (max-width: 992px) {
  .DashboardContainer.OffCanvas.OffCanvas--direction-right {
    height: 100%;
  }
}

.StickyFooter-mobile {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 295px;
  max-width: calc(100% - 20px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: env(safe-area-inset-bottom);
  border-radius: 27px;
  overflow: hidden;
  padding: 0 20px 0 20px;
  margin: 15px auto;
}

.StickyFooter-mobile a {
    justify-content: center;
    font-weight: 50;
    letter-spacing: 0.4px;
    color: #414141;
  }

.StickyFooter-mobile a .icon {
      height: 26px;
      width: 26px;
      margin-bottom: 5px;
    }

.StickyFooter-mobile a img {
      pointer-events: none;
    }

.StickyFooter-mobile a img + div {
        font-size: 10px;
      }

.StickyFooter-mobile a.exit-sidebar-icon img, .StickyFooter-mobile a.hamburger-icon img {
        height: 26px;
        width: 26px;
        margin-bottom: 5px;
      }

.StickyFooter-mobile a.active {
      background: #eeeeee;
    }

.StickyFooter-mobile a.active img {
        -webkit-filter: invert(0);
                filter: invert(0);
      }

.StickyFooter-mobile .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0'])::after {
    top: 8px;
    right: 8px;
  }

.PlayContainer .CasinoCategoryMenu, .CasinoContainer .CasinoCategoryMenu {
    align-items: center;
    color: #414141;
  }

.PlayContainer .CasinoCategoryMenu:before, .CasinoContainer .CasinoCategoryMenu:before {
      display: none;
    }

/* Hide categories when searching */

.PlayContainer .CasinoCategoryMenu .casino-search.active + .categories-menu, .CasinoContainer .CasinoCategoryMenu .casino-search.active + .categories-menu {
      display: none;
    }

.PlayContainer .CasinoCategoryMenu .casino-search, .CasinoContainer .CasinoCategoryMenu .casino-search {
      background: #3edb94;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      transition: none;
    }

.PlayContainer .CasinoCategoryMenu .casino-search .search-input, .CasinoContainer .CasinoCategoryMenu .casino-search .search-input {
        display: flex;
        width: 100%;
      }

.PlayContainer .CasinoCategoryMenu .casino-search .search-input .Input, .CasinoContainer .CasinoCategoryMenu .casino-search .search-input .Input {
          width: 100%;
        }

.PlayContainer .CasinoCategoryMenu .casino-search .search-input .Input .Input__status-line, .CasinoContainer .CasinoCategoryMenu .casino-search .search-input .Input .Input__status-line {
            display: none;
          }

.PlayContainer .CasinoCategoryMenu .casino-search i, .CasinoContainer .CasinoCategoryMenu .casino-search i {
        position: absolute;
        color: #414141;
        font-size: 30px;
        width: 50px !important;
        height: 50px !important;
        left: 10;
        padding: 10px;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        z-index: 5;
      }

.PlayContainer .CasinoCategoryMenu .casino-search i:hover, .CasinoContainer .CasinoCategoryMenu .casino-search i:hover {
          background: linear-gradient(
    90deg,
    #efefef 20%,
    #eeeeee
  );
        }

.PlayContainer .CasinoCategoryMenu .casino-search i.icon-close:before, .CasinoContainer .CasinoCategoryMenu .casino-search i.icon-close:before {
          content: '';
          background-image: url('../images/search-close.svg');
          width: 15px;
          height: 15px;
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active, .CasinoContainer .CasinoCategoryMenu .casino-search.active {
        background: #ffffff;
        box-shadow: none;
        border-radius: 4px;
        padding-left: 5px;
        color: #414141;
        height: 60px;
        border-radius: 30px;
        width: 520px;
      }

.PlayContainer .CasinoCategoryMenu .casino-search.active i, .CasinoContainer .CasinoCategoryMenu .casino-search.active i {
          box-shadow: none;
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active input, .CasinoContainer .CasinoCategoryMenu .casino-search.active input {
          color: #414141;
          border-radius: 18px;
          padding-left: 60px;
        }

.PlayContainer .CasinoCategoryMenu .casino-search.active input::-webkit-input-placeholder, .CasinoContainer .CasinoCategoryMenu .casino-search.active input::-webkit-input-placeholder {
            font-size: 20px;
            color: gray;
          }

.PlayContainer .CasinoCategoryMenu .casino-search.active input::placeholder, .CasinoContainer .CasinoCategoryMenu .casino-search.active input::placeholder {
            font-size: 20px;
            color: gray;
          }

.PlayContainer .CasinoCategoryMenu .categories-menu, .CasinoContainer .CasinoCategoryMenu .categories-menu {
      height: 70px;
      margin-left: 10px;
    }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item {
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 50px;
        line-height: 20px;
        color: #414141;
        text-transform: lowercase;
        height: auto;
        padding: 10px 20px;
      }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item:hover, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item:hover {
          background-color: var(---secondary-btn-background-hover);
        }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item.active, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item.active {
          background-color: #eeeeee;
          font-weight: normal;
          color: #414141;
        }

.PlayContainer .CasinoCategoryMenu .categories-menu .category-item a, .CasinoContainer .CasinoCategoryMenu .categories-menu .category-item a {
          text-decoration: underline;
        }

@media (max-width: 992px) {

.PlayContainer .CasinoCategoryMenu, .CasinoContainer .CasinoCategoryMenu {
      margin-right: 0
  }

      .PlayContainer .CasinoCategoryMenu .casino-search, .CasinoContainer .CasinoCategoryMenu .casino-search {
        margin-left: -12px;
      }
    }

.Game {
  overflow: hidden;
  border-radius: 6px;
}

.Game .jackpot {
    color: #f8f8f8;
    bottom: unset;
    z-index: 2;
    padding-top: 5px;
  }

.Game .jackpot span {
      line-height: 20px;
    }

.Game .overlay {
    border-radius: 4px;
  }

@media (max-width: 992px) {

.Game .overlay {
      height: 14.5vw !important
  }
    }

@media (max-width: 502px) {

.Game .overlay {
      height: 28.5vw !important
  }
    }

.Game .favorite-heart {
    display: none;
    bottom: 10px;
    top: initial;
    z-index: 10;
    width: 24px;
    height: 24px;
  }

.Game .favorite-heart .icon-heart-o:before, .Game .favorite-heart .icon-heart:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0px;
        right: 0;
      }

.Game .favorite-heart .icon-heart-o:before {
        color: darkgray;
        background-image: url('../images/favourited-disabled.svg');
      }

.Game .favorite-heart .icon-heart:before {
        color: #ffffff;
        background-image: url('../images/favourited-enabled.svg');
      }

.Game .thumbnail {
    border-radius: 4px;
    z-index: 0;
  }

.Game .thumbnail .badges {
      top: 5px;
      bottom: initial;
      flex-wrap: wrap;
    }

.Game .thumbnail .badges .badge {
        margin-bottom: 5px;
      }

.Game .thumbnail .badges .badge,
    .Game .thumbnail .ribbon {
      background: rgba(0, 25, 60, 0.88);
    }

.Game .thumbnail .ribbon + .badges {
      flex-direction: column;
    }

.Game .game-info {
    display: flex;
    flex-flow: column-reverse;
    position: relative;
    height: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

.Game .game-info .title {
      color: #414141;
      margin-bottom: 5px;
      font-weight: normal;
      order: 2;
    }

.Game .game-info .provider {
      color: #2ca6ff;
      order: 1;
    }

.Game .game-info .live-casino-info .wager-limits {
        color: #434343;
        font-weight: normal;
      }

.Game .game-info .live-casino-info .roulette-numbers {
        font-weigth: normal;
      }

.Game .game-info .live-casino-info .blackjack-info {
        color: #434343;
      }

.Game__favorite-heart {
    display: none;
  }

.Game:hover .Game__favorite-heart {
      display: block;
    }

.Game:hover .Game__favorite-heart .FavoriteHeart .icon-heart-o {
          color: #414141;
          opacity: 0.3;
          font-size: 30px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }

.Game:hover .Game__favorite-heart .FavoriteHeart .icon-heart {
          color: #ffffff;
          font-size: 30px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }

.Game:hover .game-info:before {
        display: block;
      }

.Game:hover .thumbnail {
      background-image: url('../images/play-background.png');
      background-size: 350px;
      background-position: center;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

.Game:hover .thumbnail .jackpot img {
          opacity: 1;
        }

.Game:hover .thumbnail img {
        opacity: 0;
      }

@media (max-width: 992px) {
    .Game .game-info {
      display: flex;
      position: relative;
      padding: 10px;
      height: auto;
    }

      .Game .game-info:before {
        display: none;
      }

    .Game .favorite-heart {
      position: absolute;
      display: block;
      bottom: 5px;
    }
      .Game .thumbnail:after {
        display: none;
      }
        .Game:hover .game-info:before {
          display: none;
        }
  }

.Loader .loader-icon {
    width: 200px;
    height: 200px;
    background-image: url('../images/logo.svg');
    background-size: 200px auto;
  }

.Loader .loading-spinner {
    margin: 20px auto 0;
  }

.CasinoContainer .header-and-icon > span, .JackpotDropContainer .header-and-icon > span {
      color: #414141;
      font-weight: normal !important;
    }

.CasinoContainer .grid-header a, .JackpotDropContainer .grid-header a {
      color: #2ca6ff;
      font-weight: normal;
      text-decoration: none;
    }

.CasinoContainer .GameGrid:not(.search-grid) .CasinoCategoryRow .thumbnail, .JackpotDropContainer .GameGrid:not(.search-grid) .CasinoCategoryRow .thumbnail {
        border-radius: 6px;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slide-item .overlay, .JackpotDropContainer .GameGrid:not(.search-grid) .slide-item .overlay {
        height: 10.2vw !important;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next {
        background: #37b588;
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next:before, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-next:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
        }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev {
        background: rgba(0, 25, 60, 0.88);
      }

.CasinoContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev:before, .JackpotDropContainer .GameGrid:not(.search-grid) .slick-arrow.slick-prev:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
          -webkit-transform: rotateY(180deg);
                  transform: rotateY(180deg);
        }

@media (min-width: 992px) {

.GridContainer .GameGrid {
      max-width: 96vw
  }
    }

.GridContainer .games.grid .overlay {
      height: 12.8vw !important;
    }

.GridContainer .NoGamesMessage {
    color: gray;
  }

.PlayContainer {
  background-color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
  background-image: url('../images/start-page-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.GamePlay {
  background: transparent;
}

.GamePlay .size-wrap {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

.GamePlay .home-button {
    background: #3edb94;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 40px;
    width: 40px;
    position: relative;
  }

.GamePlay .home-button:hover {
      background: #3bb98b;
    }

.GamePlay .home-button i.icon-home:before {
        content: '';
        background-image: url('../images/home-icon.svg');
        background-size: 28px 28px;
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 5px;
        left: 5.5px;
      }

.GamePlay .favorite-btn {
    width: 24px;
    height: 24px;
    position: relative;
  }

.GamePlay .favorite-btn .icon-heart-o:before, .GamePlay .favorite-btn .icon-heart:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: -3.5px;
        right: 0;
      }

.GamePlay .favorite-btn .icon-heart-o {
      color: darkgray;
    }

.GamePlay .favorite-btn .icon-heart-o:before {
        background-image: url('../images/favourited-disabled.svg');
      }

.GamePlay .favorite-btn .icon-heart:before {
        background-image: url('../images/favourited-enabled.svg');
      }

.game-history-wrapper {
  background-color: transparent;
  font-style: italic;
}

.game-history-wrapper .game-history-logo {
    -webkit-filter: none;
            filter: none;
    height: 45px;
    margin: 0 20px;
  }

.game-history-wrapper .dim-btn {
    width: 24px;
  }

.game-history-wrapper .dim-btn.on .lightbulb-fill {
      fill: #414141;
    }

.game-history-wrapper .game-history-chat {
    color: #414141;
    stroke: #414141;
    margin-right: 10px;
  }

.verify-account-wrapper .kyc-error,
  .verify-account-wrapper .kyc-success {
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
  }

.verify-account-wrapper .verify-profile {
    background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
    padding: 10px;
    border-radius: 4px;
  }

.verify-account-wrapper .verify-profile .error-message {
      font-weight: 500;
      font-style: normal;
      text-align: center;
    }

.verify-account-wrapper .verify-profile .select-type-input {
      width: 100% !important;
    }

.verify-account-wrapper .verify-profile .upload-description {
      text-align: left;
    }

.verify-account-wrapper .verify-profile .verify-profile-instruction-wrapper {
      padding-bottom: 0;
    }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper {
      flex-flow: column;
      text-align: center;
    }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .default-upload {
        display: none;
      }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload {
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
      }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload .selected-file {
          height: 42px;
          background: #2ca6ff;
          font-weight: 500;
          width: 100%;
          color: #5aa2bb;
          display: inline-block;
          padding: 10px 0 10px 0 !important;
          border-radius: 0 0 4px 4px;
        }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .custom-upload .choose-file {
          background: #2ca6ff !important;
          border-radius: 4px 4px 0 0;
          width: 100%;
          font-weight: 800 !important;
          background: none;
        }

.verify-account-wrapper .verify-profile .kyc-upload-wrapper .kyc-upload-button {
        margin-bottom: 20px;
      }

.verify-account-wrapper .tg-list .row {
      margin-top: 5px;
      border-radius: 4px;
      padding: 10px;
      background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
    }

.AcceptTacContainer .AcceptUpdatedTaC__content h1,
    .AcceptTacContainer .AcceptUpdatedTaC__content h2,
    .AcceptTacContainer .AcceptUpdatedTaC__content h3,
    .AcceptTacContainer .AcceptUpdatedTaC__content h4,
    .AcceptTacContainer .AcceptUpdatedTaC__content h5,
    .AcceptTacContainer .AcceptUpdatedTaC__content h6 {
      color: #414141;
    }

.PayNPlay {
  color: #ffffff;
}

.PayNPlay.bonus .Bonus .BonusSelector {
        display: flex;
        justify-content: center;
      }

.PayNPlay.bonus .Bonus .BonusSelector__bonus {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #2ca6ff;
          margin: 20px 0;
          max-width: 500px;
          padding-bottom: 20px;
          border-radius: 8px;
          overflow: hidden;
        }

.PayNPlay.bonus .Bonus .BonusSelector__bonus h3 {
            font-size: 2em;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus a {
            order: 2;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus button {
            margin-block: var(--pnp-spacing);
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus__content {
            padding: 0px 20px;
            text-align: left;
          }

.PayNPlay.bonus .Bonus .BonusSelector__bonus__image img {
              max-width: unset;
            }

.PayNPlay.bonus button {
      color: #ffffff;
    }

.is-pnp .PayNPlayContainer .error_message.visible,
  .is-pnp .PayNPlayContainer .minimum_amount,
  .is-pnp .PayNPlayContainer .error-message {
    margin-bottom: 15px;
    font-weight: 600;
  }

.is-pnp .PayNPlayContainer form {
    flex-flow: column;
  }

.is-pnp .PayNPlayContainer .tg-input {
    margin: 0;
  }

.is-pnp .PayNPlayContainer .tg-input input {
      width: 100% !important;
      background: none;
      color: #ffffff;
      font-size: 30px;
      border: none;
      border-bottom: 1px solid #ffffff;
      padding-bottom: 15px;
      caret-color: #414141;
      border-radius: 0;
    }

@media (max-width: 992px) {

.is-pnp .PayNPlayContainer .tg-input input {
        font-size: 30px !important
    }
      }

@media (max-width: 540px) {

.is-pnp .PayNPlayContainer .tg-input input {
        font-size: 20px !important
    }
      }

.is-pnp .PayNPlayContainer .AcceptUpdatedTaC {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

.is-pnp .PayNPlayContainer .AcceptUpdatedTaC h2 {
      color: #ffffff;
    }

.is-pnp .PayNPlayContainer .disclaimer {
    font-weight: 500;
  }

.is-pnp .PayNPlayContainer .question {
    margin-top: 0;
    font-weight: 500;
  }

.is-pnp .PayNPlayContainer .question .button.secondary.small {
      background: none;
      border-radius: 0;
      box-shadow: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500 !important;
      text-transform: unset;
      display: inline-flex;
      padding: 4px;
      text-decoration: underline;
      font-style: italic;
    }

.is-pnp .PayNPlayContainer .InlinePnp .button {
      margin-bottom: 15px;
    }

.is-pnp .PayNPlayContainer .InlinePnp .tg-input input {
        margin-bottom: 0;
        font-weight: 800;
        height: 80px;
        font-size: 60px;
        border: none;
        text-align: center;
      }

@media (max-width: 992px) {

.is-pnp .PayNPlayContainer .InlinePnp .tg-input input {
          font-size: 60px !important
      }
        }

@media (max-width: 540px) {

.is-pnp .PayNPlayContainer .InlinePnp .tg-input input {
          font-size: 2em !important
      }
        }

.is-pnp .PayNPlayContainer .DetailsStep > * {
      margin-bottom: 20px;
    }

.is-pnp .PayNPlayContainer .DetailsStep .save-button {
      margin-bottom: 15px;
    }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper {
      width: 285px;
    }

@media (max-width: 540px) {

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper {
        width: 100%
    }
      }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-input,
      .is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-select {
        margin: 0;
      }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-input .tg-select__status-text,
        .is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-input .tg-input__status-text,
        .is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-select .tg-select__status-text,
        .is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-select .tg-input__status-text {
          display: none;
        }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .tg-input .Input input {
          background-image: url('../images/input-phone.svg');
          background-repeat: no-repeat;
          padding-left: 30px;
          background-position-y: center;
        }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .calling-code {
        background: transparent;
        border-bottom: 1px solid #ffffff;
        width: 180px;
        margin-right: 15px;
      }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .calling-code input {
          height: 40px;
          color: #ffffff;
          font-weight: 800;
          font-family: 'Baloo', 'Fira Sans', sans-serif;
          line-height: 40px;
          font-size: 18px;
          background: none;
          padding-left: 10px;
          font-style: italic;
        }

.is-pnp .PayNPlayContainer .DetailsStep .phone-wrapper .calling-code select {
          cursor: pointer;
          background-color: transparent;
          padding-bottom: 5px;
          color: #ffffff;
          font-weight: 800;
          font-size: 18px;
          font-style: italic;
        }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input {
      width: 285px;
    }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input .tg-input__status-text {
        display: none;
      }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input .Input input {
          font-size: 18px !important;
          padding-bottom: 5px;
          text-align: left;
          font-weight: 800;
        }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input .Input input::-webkit-input-placeholder {
            font-style: italic;
            text-transform: uppercase;
          }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input .Input input::placeholder {
            font-style: italic;
            text-transform: uppercase;
          }

.is-pnp .PayNPlayContainer .DetailsStep .tg-input input[type='email'] {
        background-image: url('../images/input-email.svg');
        background-repeat: no-repeat;
        padding-left: 40px;
        background-position-y: center;
      }

@media (max-width: 540px) {

.is-pnp .PayNPlayContainer .DetailsStep .tg-input {
        width: 100%
    }
      }

.is-pnp .PayNPlayContainer .communication-consent {
    justify-content: center;
    margin-top: 0;
    width: auto;
  }

.is-pnp .PayNPlayContainer .communication-consent .Checkbox .flex {
        justify-content: center;
      }

.is-pnp .PayNPlayContainer .communication-consent .Checkbox label {
        margin: 0;
        width: initial;
      }

@media (max-width: 466px) {

.is-pnp .PayNPlayContainer .Prompt .prompt-buttons {
        display: flex;
        flex-direction: column
    }

        .is-pnp .PayNPlayContainer .Prompt .prompt-buttons .button {
          margin: 0;
          margin-top: 10px;
        }
      }

.SignUpContainer {
  color: #ffffff;
}

.SignUpContainer .Layout {
    color: #ffffff;
  }

.SignUpContainer .Layout__step {
      background: linear-gradient(to right, #5bd2fe 5%, #2aa5ff 70%);
    }

.SignUpContainer .Layout__step__wrapper .Icons {
          color: #ffffff;
        }

.SignUpContainer .Layout__step__wrapper .Icons i.icon-money-bill::before {
            content: '\e91a';
          }

.SignUpContainer .Layout__step__wrapper .Icons__icon--active {
              color: #42d19d;
            }

.SignUpContainer .Layout__step__wrapper__content * {
            color: #ffffff !important;
          }

.SignUpContainer .Layout__step__wrapper__content p {
            font-size: 0.6em;
          }

.SignUpContainer .Layout--deposit {
      text-align: center;
    }

.SignUpContainer .Layout--deposit .Deposit {
        display: flex;
        flex-direction: column;
      }

.SignUpContainer .Layout--deposit .Deposit .button.primary {
          margin-bottom: 10px;
        }

.reality-check {
  color: #ffffff;
  text-align: center;
}

.reality-check .header {
    background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%) !important;
    justify-content: center;
    width: 100% !important;
  }

.Popup__header {
    color: #ffffff;
  }

.Popup__content {
    overflow: auto;
    font-style: italic;
    color: #414141;
    border-radius: 4px;
  }

.Popup__content h1,
    .Popup__content h2,
    .Popup__content h3,
    .Popup__content h4,
    .Popup__content h5 {
      color: #414141;
    }

.Popup__content .Select,
    .Popup__content .Input {
      border-radius: 5px;
      border: solid 1px #414141;
    }

.Popup__content .Select__label, .Popup__content .Input__label {
        color: #414141;
      }

.Popup__children {
    color: #414141;
  }

.SuccessfulDepositContainer .Popup__children {
        color: #ffffff;
    }

.SuccessfulDepositContainer .Popup__children h2 {
        color: #ffffff;
      }

.SuccessfulDepositContainer .Popup__children img {
        max-width: 180px;
      }

.successful-deposit.popup .popup-content h2 {
      text-transform: uppercase;
    }

.successful-deposit.popup .popup-content > div {
      background: none;
    }

.ContentPreviewContainer form {
    display: flex;
    align-items: center;
  }

@media (max-width: 900px) {

.ContentPreviewContainer form {
      flex-direction: column;
      align-items: unset
  }
    }

.casino-promotion {
  background-color: #4fc5ff;
}

.casino-promotion .casino-promotion-image,
  .casino-promotion .casino-promotion-video video {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

.casino-promotion .stats-wrapper,
  .casino-promotion .summary p {
    font-weight: 600;
  }

.casino-promotion .summary img {
      height: 100px;
    }

.casino-promotion .actions.button.primary {
    font-size: 18px;
  }

.casino-promotion .casino-promotion-bg {
    opacity: 1;
  }

.casino-promotion .casino-promotion-bg::before {
      background: none;
    }

.casino-promotion .casino-promotion-bg::after {
      background: none;
    }

@media (max-width: 992px) {
    .casino-promotion .stats-wrapper {
      display: none;
    }

    .casino-promotion .summary p,
    .casino-promotion a.actions {
      margin: 0;
      align-self: center;
    }

    .casino-promotion .summary {
      order: 1;
      width: 100%;
      margin-bottom: 10px;
    }

    .casino-promotion .casino-promotion-content {
      flex-direction: column-reverse;
    }
  }

.rg-gaming-content .popup-content {
    background: var(--prmary-colori) !important;
    text-align: center;
  }

.rg-gaming-content .popup-content > div {
      min-height: unset;
      width: 100% !important;
    }

.rg-gaming-content .popup-content .button-wrapper {
      display: block;
    }

.SessionSummary .Summary .date {
      color: #ffffff !important;
      font-weight: 400;
    }

.SessionSummary .Summary .info {
      font-weight: bold;
    }

.SessionSummary .Summary .info span:nth-child(2) {
        color: #ffffff !important;
        font-weight: 400;
      }

.SessionSummary .ResponsibleGaming a {
      color: #ffffff !important;
    }

.rg-wrapper {
  font-style: italic;
}

.rg-wrapper .SessionClock {
    position: relative;
    padding-top: 3px;
  }

.rg-wrapper .SessionClock i.icon-live-sports {
      margin-right: 18px;
    }

.rg-wrapper .SessionClock i.icon-live-sports:before {
        content: '';
        background-image: url('../images/clock.svg');
        width: 14px;
        height: 14px;
        position: absolute;
        top: 4.5px;
      }

.ResponsibleGamingWidget .AccordionWidget .AccordionItem .AccordionItem__header, .HistoryContainer .AccordionWidget .AccordionItem .AccordionItem__header, .cashier-accordion .AccordionWidget .AccordionItem .AccordionItem__header {
        box-shadow: none;
        margin-bottom: -10px;
        margin-top: 10px;
      }

.ResponsibleGamingWidget .AccordionWidget .AccordionItem .AccordionItem__content, .HistoryContainer .AccordionWidget .AccordionItem .AccordionItem__content, .cashier-accordion .AccordionWidget .AccordionItem .AccordionItem__content {
        border-radius: 0px 0px 10px 10px !important;
      }

.ResponsibleGamingWidget .AccordionWidget .AccordionItem .AccordionItem__content .navigation, .HistoryContainer .AccordionWidget .AccordionItem .AccordionItem__content .navigation, .cashier-accordion .AccordionWidget .AccordionItem .AccordionItem__content .navigation {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
        }

.ResponsibleGamingWidget .AccordionWidget .AccordionItem .AccordionItem__content .navigation .button, .HistoryContainer .AccordionWidget .AccordionItem .AccordionItem__content .navigation .button, .cashier-accordion .AccordionWidget .AccordionItem .AccordionItem__content .navigation .button {
            justify-content: space-between;
          }

.AccordionWidget .AccordionItem {
    margin-top: 1px;
    color: #414141;
  }

.AccordionWidget .AccordionItem:only-child {
      border-radius: 4px;
    }

.AccordionWidget .AccordionItem .AccordionItem__header {
      background-color: #ffffff;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-bottom: 10px;
    }

.AccordionWidget .AccordionItem .AccordionItem__header:hover {
        background-color: #eeeeee;
      }

.AccordionWidget .AccordionItem .AccordionItem__header .responsiblegaming-item-header .info-amount,
      .AccordionWidget .AccordionItem .AccordionItem__header .responsiblegaming-item-header .title-text,
      .AccordionWidget .AccordionItem .AccordionItem__header .AccordionItem__expand-icon {
        color: #ffffff;
      }

.AccordionWidget .AccordionItem--expanded .AccordionItem__header {
        box-shadow: none;
        margin-bottom: -45px;
      }

.AccordionWidget .AccordionItem--expanded .AccordionItem__header__tooltop-icon {
          top: 31px;
        }

.AccordionWidget .AccordionItem--expanded .AccordionItem__header:hover {
          background: #ffffff;
        }

.AccordionWidget .AccordionItem .AccordionItem__header--expanded {
      box-shadow: 0;
    }

.AccordionWidget .AccordionItem .AccordionItem__content {
      background-color: #ffffff;
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 1.1em;
    }

.AccordionWidget .AccordionItem .AccordionItem__content p {
        color: gray;
        font-size: 15px;
      }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-limit-selector .tg-input {
            margin: 0 !important;
          }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-limit-selector .tg-input .Input {
              background-color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
              border-radius: 5px;
            }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-limit-selector .tg-input .Input input {
                font-size: 1rem;
                caret-color: #ffffff;
                color: #414141;
                font-weight: bold;
                padding-bottom: 8px;
                background: transparent;
              }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-limit-selector .tg-input .Input__status-line {
                background-color: #42d19d;
              }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-limit-selector .button {
            width: auto;
            padding: 0 2.5em;
            display: grid;
            place-items: center;
          }

.AccordionWidget .AccordionItem .AccordionItem__content .ResponsibleGamingWidget__timespan-title {
          font-weight: normal;
        }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .custom-amount-and-submit .tg-input .Input__status-line {
                display: none;
              }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .custom-amount-and-submit .tg-input .Input label {
                color: #414141;
              }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .custom-amount-and-submit button {
            background: #42d19d;
            border-radius: 0 5px 5px 0;
          }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .custom-amount-and-submit .error-message {
            color: #d63e3e;
            font-size: 13px;
            left: 0;
          }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .account-selection {
          background: inherit;
        }

.AccordionWidget .AccordionItem .AccordionItem__content .payment-method-item-content .account-selection .tg-select .Select select {
              background: #ffffff;
              border-radius: 4px;
              color: #414141;
            }

.responsiblegaming-signup .AmountItem, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .AmountItem {
    margin: 10px;
  }

.responsiblegaming-signup .custom-amount-increment,
  .responsiblegaming-signup .custom-amount-decrement,
  .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-increment,
  .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-decrement {
    color: #ffffff !important;
    background: #3edb94 !important;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

.responsiblegaming-signup .custom-amount-increment:hover, .responsiblegaming-signup .custom-amount-decrement:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-increment:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-decrement:hover {
      background: #3bb98b !important;
    }

.responsiblegaming-signup .custom-amount-input .Input input[type='number'], .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number'] {
      padding-top: 13px;
      background: #ffffff !important;
      color: black !important;
      caret-color: #414141;
    }

.responsiblegaming-signup .custom-amount-input .Input input[type='number']::-webkit-input-placeholder, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number']::-webkit-input-placeholder {
        color: #474747 !important;
      }

.responsiblegaming-signup .custom-amount-input .Input input[type='number']::placeholder, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount-input .Input input[type='number']::placeholder {
        color: #474747 !important;
      }

.responsiblegaming-signup .limit-info-container, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .limit-info-container {
    text-align: center;
    text-transform: uppercase;
  }

.responsiblegaming-signup .custom-amount .error-message span, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .custom-amount .error-message span {
      color: black;
    }

.responsiblegaming-signup .timespan-label, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-label {
    color: #ffffff;
    font-weight: bold;
  }

.responsiblegaming-signup .timespan-selector .button, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button {
      background: none !important;
      box-shadow: none !important;
      font-weight: normal !important;
      font-size: 14px !important;
      padding: 2px;
    }

.responsiblegaming-signup .timespan-selector .button.selected, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button.selected {
        background: none !important;
        text-decoration: underline;
      }

.responsiblegaming-signup .timespan-selector .button:hover, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button:hover {
        text-decoration: underline;
      }

@media (max-width: 540px) {

.responsiblegaming-signup .timespan-selector .button, .responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container .timespan-selector .button {
        font-size: 12px !important
    }
      }

.pending-container {
  padding: 20px;
}

.pending-container button {
    margin-top: 10px;
  }

.PayNPlay .limit-info-container,
  .PayNPlay .notice,
  .PayNPlay .Prompt h2 {
    color: #ffffff;
    flex-direction: unset;
  }

.ErrorBoundary {
  padding: 0;
}

.ErrorBoundary .error-message-container {
    text-align: center;
    border-radius: 4px;
  }

.ErrorBoundary .error-message-container h1,
    .ErrorBoundary .error-message-container p {
      color: #ffffff;
    }

.notification {
  background: #2aa5ff;
  border-radius: 0 0 4px 4px;
}

.notification .timeout-bar {
    background: #ffffff;
  }

.notification .content .head .date {
      margin-top: 2px;
    }

.notification .notification-buttons .notification-button {
      color: #ffffff;
      border-top: 1px solid #ffffff;
    }

.notification .notification-buttons .notification-button:hover {
        color: rgba(255,255,255, 0.8);
      }

.notifications-badge,
.notifications-badge--right {
  background-color: #42d19d !important;
  color: #ffffff !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 25px !important;
}

.messages-list-item {
  background-color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
  border-radius: 4px;
}

.messages-list-item:not(.open).messages-list-item:hover {
    background-color: #2ca6ff;
  }

.messages-list-item:not(.open).messages-list-item:hover .message-wrapper::after {
      background: linear-gradient(transparent, #2ca6ff);
    }

.messages-list-item .message-wrapper::after {
    background: linear-gradient(transparent, #2ca6ff);
  }

.messages-list-item header {
  margin-bottom: 0px !important;
}

.messages-list-item header .title::after {
      background-color: #ffffff;
      top: 9px;
    }

.messages-list-item header .time {
    color: #ffffff;
  }

.tg-list .row.active .detail {
        background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
        justify-content: space-evenly;
      }

.tg-list .row.active .detail:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
        }

.tg-list .row .info:before {
        background: #5bc2ff;
      }

.Tournament {
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.Tournament img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

.TournamentDetailContainer .tournament-banner .banner-content {
    background-color: #2ca6ff;
  }

.TournamentDetailContainer .tournament-content {
    color: #414141;
  }

.TournamentDetailContainer .tournament-content .column:first-child {
      padding: 20px;
      margin: 20px;
    }

.TournamentDetailContainer .tournament-content .column:first-child h1,
      .TournamentDetailContainer .tournament-content .column:first-child h2,
      .TournamentDetailContainer .tournament-content .column:first-child h3,
      .TournamentDetailContainer .tournament-content .column:first-child h4,
      .TournamentDetailContainer .tournament-content .column:first-child h5,
      .TournamentDetailContainer .tournament-content .column:first-child h6 {
        color: #414141;
      }

.TournamentDetailContainer .tournament-content .column:first-child h1:first-child, .TournamentDetailContainer .tournament-content .column:first-child h2:first-child, .TournamentDetailContainer .tournament-content .column:first-child h3:first-child, .TournamentDetailContainer .tournament-content .column:first-child h4:first-child, .TournamentDetailContainer .tournament-content .column:first-child h5:first-child, .TournamentDetailContainer .tournament-content .column:first-child h6:first-child {
          margin-top: 0;
        }

.TournamentDetailContainer .tournament-content .leaderboard {
      background-color: #ffffff;
      border-radius: 4px;
    }

.TournamentDetailContainer .tournament-content .leaderboard-header ~ .leaderboard {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

.TournamentDetailContainer .tournament-content .leaderboard-header {
      background-color: #2ca6ff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #ffffff;
      padding-bottom: 20px;
      font-weight: bold;
    }

.TournamentDetailContainer .tournament-join .button {
      transition: none;
    }

.Promotion {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: #414141;
  width: calc(100% / 3 - 40px);
}

.Promotion img {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 100%;
  }

.Promotion .promotion-content-wrapper {
    padding: 15px;
  }

.Promotion .promotion-content-wrapper .title {
      font-weight: 700;
      font-size: 1.4em;
    }

.PromotionDetailContainer .promotion-banner {
    position: relative;
  }

.PromotionDetailContainer .banner-content {
    position: absolute;
    background: transparent !important;
    top: 50%;
    -webkit-transform: TranslateY(-50%);
            transform: TranslateY(-50%);
    left: 10%;
  }

@media (max-width: 992px)
    {

.PromotionDetailContainer .banner-content {
      left: 0px
  }
    }

.PromotionDetailContainer .banner-image {
    height: 35vh;
    min-height: 600px;
    background-position: bottom right !important;
  }

@media (max-width: 992px) {

.PromotionDetailContainer .banner-image {
      height: 35vh;
      min-height: unset;
      background-position: center !important
  }
    }

@media (max-width: 750px) {

.PromotionDetailContainer .banner-image {
      height: 40vh
  }
    }

.PromotionDetailContainer .promotion-content {
    border-radius: 4px;
    color: #414141;
    padding: 20px;
    margin: 20px;
  }

.PromotionDetailContainer .promotion-content h1,
    .PromotionDetailContainer .promotion-content h2,
    .PromotionDetailContainer .promotion-content h3,
    .PromotionDetailContainer .promotion-content h4,
    .PromotionDetailContainer .promotion-content h5,
    .PromotionDetailContainer .promotion-content h6 {
      color: #414141;
    }

.PromotionDetailContainer .promotion-content h1:first-child, .PromotionDetailContainer .promotion-content h2:first-child, .PromotionDetailContainer .promotion-content h3:first-child, .PromotionDetailContainer .promotion-content h4:first-child, .PromotionDetailContainer .promotion-content h5:first-child, .PromotionDetailContainer .promotion-content h6:first-child {
        margin-top: 0;
      }

@media (min-width: 992px) {

.PromotionDetailContainer .promotion-content {
      width: auto
  }
    }

.CasinoCategoryRow {
  width: auto !important;
  height: 18.05vw;
}

.CasinoCategoryRow .overlay {
    display: none;
  }

.CasinoCategoryRow .category-name {
    bottom: 0;
    top: 100%;
  }

.CasinoCategoryRow .category-name .title {
      font-size: 18px;
      font-weight: normal;
      color: #434343;
    }

.JackpotDropContainer main > header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    background: linear-gradient(to right, #5bd2fe 30%, #2aa5ff 70%);
    text-transform: uppercase;
  }

@media (min-width: 993px) {

.JackpotDropContainer main > header {
      padding-left: 20px;
      padding-right: 20px
  }
    }

.JackpotDropContainer .JackpotWidget {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

.JackpotDropContainer .JackpotWidget__badge {
      font-weight: bold;
      border-radius: 2px;
      text-shadow: initial;
    }

.JackpotDropContainer .JackpotWidget__info::before {
        content: '';
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        margin-right: 20px;
      }

.JackpotDropContainer .JackpotWidget__info--clock::before {
        background-image: url('../images/clock-icon.svg');
      }

.JackpotDropContainer .JackpotWidget__info--jackpot::before {
        background-image: url('../images/diamond-icon.svg');
      }

body {
  --gameplay-topbar-height: 0px;
  --gameplay-header-height: 56px;
  --gameplay-bottombar-height: 0px;
  --gameplay-frame-darkmode-bgcolor: #414141;
  --gameplay-popunder-overlay-bgcolor: rgba(0, 0, 0, 0.8);
  --gameplay-header-bgcolor: linear-gradient(to right, #5bd2fe 30%, #2aa5ff 70%);
}

body.is-desktop {
    --gameplay-header-height: 60px;
    --gameplay-bottombar-height: 75px;
  }

body.jurisdiction-sga {
    --gameplay-topbar-height: 30px;
  }

.PlayContainer .button.icon {
  }

.PlayContainer .GamePlay .GamePlayHeader {
      background: url('../images/logo.svg'), var(--gameplay-header-bgcolor);
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 60px;
      color: #414141;
    }

.PlayContainer .GamePlay .GamePlayHeader__hamburger {
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin: 0 10px 0px 5px !important;
      }

.PlayContainer .GamePlay .GamePlayHeader__hamburger img {
          margin-top: 5px;
          height: 16px !important;
        }

@media (max-width: 992px) {

.PlayContainer .GamePlay .GamePlayHeader__hamburger img {
            height: 15px
        }
          }

.PlayContainer .GamePlay .GamePlayHeader__hamburger:hover {
          background: #eeeeee;
        }

.PlayContainer .GamePlay .GamePlayHeader__back button {
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
          background: #ffffff;
          color: #414141;
          border-radius: 50%;
        }

.PlayContainer .GamePlay .GamePlayHeader__back button i {
            font-size: 28px;
          }

.PlayContainer .GamePlay .GamePlayHeader__back span {
          font-size: 16px;
        }

.PlayContainer .GamePlay .GamePlayHeader__menu,
      .PlayContainer .GamePlay .GamePlayHeader__menu-close {
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        background: #ffffff;
        color: #414141;
        border-radius: 50%;
        margin-left: 4px;
      }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit-desktop {
        height: auto;
      }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit {
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #42d19d;
        border-radius: 1.5em;
      }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .QuickAmounts .button .show {
            display: flex;
            justify-content: center;
            align-items: center;
          }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .tg-select .Select select {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: calc(20px + var(--trailing-lane-width));
          }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .tg-input .Input .Input__status-line {
              display: none;
            }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form .tg-input .Input input {
              height: 37px;
            }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form a {
            font-size: 18px;
            line-height: 40px;
            text-transform: uppercase;
          }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit form a i {
              display: none;
            }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit .QuickDeposit__account-select {
            width: auto;
          }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit .QuickDeposit form > * {
            margin: auto;
          }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit .QuickDepositMobile {
          display: flex;
          background: #ffffff;
          border-radius: 50%;
          height: auto;
        }

.PlayContainer .GamePlay .GamePlayHeader__quick-deposit .QuickDepositMobile i {
            color: #414141;
          }

.PlayContainer .GamePlay .GamePlayHeader__user .button {
          display: none;
        }

.PlayContainer .GamePlay .GamePlayHeader__name {
        display: flex;
        margin-right: 8px;
      }

.PlayContainer .GamePlay .GamePlayHeader .MembershipActions .button:not(.user-balance) {
            font-size: 1.3em;
            padding: 0 2em;
            height: 40px;
            line-height: 40px;
            margin: 0;
          }

@media (max-width: 992px) {
        .PlayContainer .GamePlay .GamePlayHeader .MembershipActions {
          display: none;
        }
      }

.PlayContainer .GamePlay .PopUnder--closed .GameDetails {
        height: 70px;
        margin-top: 5px;
      }

.PlayContainer .GamePlay .PopUnder--open .GameDetails {
        height: 100%;
        margin-top: 15px;
      }

.PlayContainer .GamePlay .GameDetails {
      background: #2ca6ff;
      color: #ffffff;
      padding: 5px 10px 5px 10px;
    }

.PlayContainer .GamePlay .GameDetails__fav {
        background: #ffffff;
        color: lightgray;
      }

.PlayContainer .GamePlay .GameDetails__fav i {
          font-size: 25px;
        }

.PlayContainer .GamePlay .GameDetails__quick-actions button {
          background: #ffffff;
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
          color: lightgray;
          border-radius: 50%;
        }

.PlayContainer .GamePlay .GameDetails__quick-actions button i {
            font-size: 25px;
          }

.PlayContainer .GamePlay .GameDetails__categories-nav {
        margin-bottom: 10px;
      }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu {
          margin-top: 5px;
          color: #414141;
        }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search {
            background-color: #ffffff;
            box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
            color: #414141;
            margin-left: 0;
            margin-right: 15px;
          }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search i {
              width: 50px !important;
              height: 50px !important;
              background-color: #ffffff;
              color: #414141;
              font-size: 30px;
              margin-left: 0 !important;
            }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .active {
            border-radius: 300px;
            height: 50px;
          }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .active i {
              margin-left: 5px;
              box-shadow: none;
            }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .active input {
              color: #414141;
              border-radius: 18px;
              padding-left: 50px;
            }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .active input::-webkit-input-placeholder {
                font-size: 15px;
                color: gray;
              }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .active input::placeholder {
                font-size: 15px;
                color: gray;
              }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu {
            display: contents;
          }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu::after {
              background: none !important;
            }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item {
              background-color: #ffffff;
              color: #414141;
              font-size: 15px;
              border-radius: 20px;
              width: auto;
              height: 40px;
              line-height: 22px;
              text-transform: lowercase;
            }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item:hover {
                background-color: linear-gradient(
    90deg,
    #efefef 20%,
    #eeeeee
  );
              }

.PlayContainer .GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item.active {
                background-color: #eeeeee;
              }

.PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .last-slide a:last-child {
              color: #ffffff;
            }

@media (min-width: 993px)
          {
            .PlayContainer .GamePlay .GameDetails__category .GameGrid .track {
              -webkit-transform : translateX(-12px) !important;
                      transform : translateX(-12px) !important;
            }
          }

.PlayContainer .GamePlay .GameDetails__category .GameGrid .slider {
            height: 100%;
          }

.PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .list {
              margin-left: 50px;
            }

.PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .list .thumbnail {
                width: auto;
                height: auto;
              }

@media (min-width: 1190px) {
              .PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .list {
                margin-left: 62px;
              }
          }

.PlayContainer .GamePlay .GameDetails__category .Game {
          width: 17vw !important;
          background: none;
          box-shadow: none;
        }

.PlayContainer .GamePlay .GameDetails__category .Game img {
            border-radius: 4px;
          }

.PlayContainer .GamePlay .GameDetails__category .Game .game-info {
            position: absolute;
          }

.PlayContainer .GamePlay .GameDetails__category .Game .game-info .title {
              font-size: 17px;
              color: #ffffff;
            }

.PlayContainer .GamePlay .GameDetails__category .Game .game-info .provider {
              font-size: 10px;
              color: #ffffff;
            }

@media (max-width: 992px) {

.PlayContainer .GamePlay .GameDetails__category .Game {
            width: 25vw !important
        }
          }

@media (max-width: 500px) {

.PlayContainer .GamePlay .GameDetails__category .Game {
            width: 70vw !important
        }
            .PlayContainer .GamePlay .GameDetails__category .Game .game-info {
              position: relative;
              margin: 0;
              padding-left: 0;
            }

              .PlayContainer .GamePlay .GameDetails__category .Game .game-info .title {
                font-size: 17px;
                color: #414141;
              }

              .PlayContainer .GamePlay .GameDetails__category .Game .game-info .provider {
                font-size: 10px;
                color: #2ca6ff;
              }
          }

@media (max-width: 992px) {

.PlayContainer .GamePlay .GameDetails__category {
          margin-bottom: 50px
      }
          .PlayContainer .GamePlay .GameDetails__category .NoGamesMessage {
            color: darkgray;
            padding: 30px;
          }
              .PlayContainer .GamePlay .GameDetails__category .GameGrid .slider .list {
                margin: 0 3px 0 3px;
              }

          .PlayContainer .GamePlay .GameDetails__category .Game {
            background: none;
            box-shadow: none;
          }

            .PlayContainer .GamePlay .GameDetails__category .Game img {
              border-radius: 4px;
            }

            .PlayContainer .GamePlay .GameDetails__category .Game .provider {
              color: #2ca6ff;
            }
        }

@media (max-width: 992px) {

.PlayContainer .GamePlay .GameDetails {
        background-color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
        color: #414141;
        height: auto !important;
        margin: 0 !important;
        margin: 0
    }

        .PlayContainer .GamePlay .GameDetails .CasinoCategoryMenu {
          padding: 0;
        }
            .PlayContainer .GamePlay .GameDetails .CasinoCategoryMenu .active i {
              left: 7px;
            }

          .PlayContainer .GamePlay .GameDetails .CasinoCategoryMenu .casino-search {
            margin-left: 10px;
          }

          .PlayContainer .GamePlay .GameDetails .CasinoCategoryMenu .categories-menu {
            display: none;
          }

        .PlayContainer .GamePlay .GameDetails__info {
          line-height: 40px;
        }
          .PlayContainer .GamePlay .GameDetails__info h1 {
            color: #414141;
            font-weight: normal;
          }
      }

.PlayContainer .GamePlay .GameFrame__footer {
        display: none;
      }

.QuickDepositContainer .popup-content i {
      display: none;
    }

.MigrationContainer .main-content-container {
    color: #434343;
    text-align: center;
    padding: 50px 0;
  }

.MigrationContainer .main-content-container h1,
    .MigrationContainer .main-content-container h2,
    .MigrationContainer .main-content-container h3,
    .MigrationContainer .main-content-container h4,
    .MigrationContainer .main-content-container h5,
    .MigrationContainer .main-content-container h6 {
      color: #434343;
    }

@media (max-width: 992px) {

.MigrationContainer .main-content-container {
      padding-left: 10px;
      padding-right: 10px
  }
    }

.IndexContainerPnp .fixed-position {
    margin-top: 10px;
    padding-bottom: 100px;
  }

.IndexContainerPnp .fixed-position .actions .button {
        padding: 0;
        width: 330px;
        text-transform: uppercase;
        position:relative;
        scale: 1;
        overflow: hidden;
      }

@media (max-width: 530px) {

.IndexContainerPnp .fixed-position .actions .button {
          width: 250px;
          font-size: 20px
      }
        }

.IndexContainerPnp .fixed-position .actions .button::after {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.4);
          width: 10%;
          height: 100%;
          top: 0;
          left: -100px;
          -webkit-filter: blur(10px);
                  filter: blur(10px);
          -webkit-animation-name: shine;
                  animation-name: shine;
          -webkit-animation-delay: 2000ms;
                  animation-delay: 2000ms;
          -webkit-animation-duration: 2500ms;
                  animation-duration: 2500ms;
          -webkit-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
                  animation-timing-function: linear;
        }

.IndexContainerPnp .fixed-position .actions .button::before {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.60);
          width: 20%;
          height: 100%;
          top: 0;
          left: -100px;
          -webkit-filter: blur(30px);
                  filter: blur(30px);
          -webkit-animation-name: shine;
                  animation-name: shine;
          -webkit-animation-delay: 2000ms;
                  animation-delay: 2000ms;
          -webkit-animation-duration: 2500ms;
                  animation-duration: 2500ms;
          -webkit-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
                  animation-timing-function: linear;
        }

@media (max-width: 992px)
    {

.IndexContainerPnp .fixed-position {
      padding-bottom: 0
  }
    }

.IndexContainerPnp .index-background-active + .fixed-position .Carousel,
    .IndexContainerPnp .index-background-active + .fixed-position .Features,
    .IndexContainerPnp .index-background-active + .fixed-position .brand-description h2 {
      display: none;
    }

.IndexContainerPnp .PayNPlayContainer {
    margin-top: 250px;
  }

@media (max-width: 992px) {

.IndexContainerPnp .PayNPlayContainer {
      margin-top: 80px
  }
    }

.IndexContainerPnp .brand-description + .PayNPlayContainer {
    margin-top: 0;
  }

.IndexContainerPnp .mermaid-notify {
    position: absolute;
    height: 100%;
    width: 100%;
    top:  0;
    right: 0px;
    pointer-events: none;
    -webkit-animation: mermaid-notify 3s 75s forwards;
            animation: mermaid-notify 3s 75s forwards;
  }

@media (max-width:728px)
    {

.IndexContainerPnp .mermaid-notify {
      display: none
  }
    }

.IndexContainerPnp .mermaid {
    position: absolute;
    right: -300px;
    top: 50%;
    -webkit-transform: TranslateY(-150%);
            transform: TranslateY(-150%);
    -webkit-animation: mermaid-side 3s 2s forwards;
            animation: mermaid-side 3s 2s forwards;
  }

.IndexContainerPnp .mermaid img {
      width: 300px;
    }

.IndexContainerPnp .mermaid-bottom {
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: -250px;
    -webkit-transform: TranslateX(-50%);
            transform: TranslateX(-50%);
    -webkit-animation: mermaid-bottom 3s 10s forwards;
            animation: mermaid-bottom 3s 10s forwards;
  }

.IndexContainerPnp .mermaid-bottom img {
      width: 300px;
    }

@media (max-width:728px)
    {

.IndexContainerPnp .mermaid-bottom {
      display: none
  }
    }

.IndexContainerPnp .chat-bubble {
    position: absolute;
    right: 180px;
    top: 50%;
    -webkit-transform: TranslateY(-230%) Scale(0%);
            transform: TranslateY(-230%) Scale(0%);
    -webkit-animation: chat-bubble 2s 4s forwards;
            animation: chat-bubble 2s 4s forwards;
  }

.IndexContainerPnp .chat-bubble img {
      width: 300px;
    }

.IndexContainerPnp .bubbles {
    height: 500px;
  }

.IndexContainerPnp .bubbles img {
      position: absolute;
      width: 400px;
      left: 50%;
    }

.IndexContainerPnp .bubbles .bubble-first {
      -webkit-transform: translateX(60%);
              transform: translateX(60%);
      bottom: -400px;
      -webkit-animation: bubble-first 10s 3s forwards;
              animation: bubble-first 10s 3s forwards;
    }

.IndexContainerPnp .bubbles .bubble-second {
      -webkit-transform: translateX(-40%);
              transform: translateX(-40%);
      bottom: -400px;
      -webkit-animation: bubble-second 10s 4s forwards;
              animation: bubble-second 10s 4s forwards;
    }

.IndexContainerPnp .bubbles .bubble-third {
      -webkit-transform: translateX(-140%);
              transform: translateX(-140%);
      bottom: -400px;
      -webkit-animation: bubble-third 10s 2s forwards;
              animation: bubble-third 10s 2s forwards;
    }

@media (max-width:728px)
    {

.IndexContainerPnp .bubbles {
      height: 300px
  }

      .IndexContainerPnp .bubbles img {
        width: 150px;
      }

      .IndexContainerPnp .bubbles .bubble-first {
        -webkit-transform: translateX(20%);
                transform: translateX(20%);
        bottom: -400px;
      }

      .IndexContainerPnp .bubbles .bubble-second {
        -webkit-transform: translateX(-40%);
                transform: translateX(-40%);
        bottom: -400px;
      }

      .IndexContainerPnp .bubbles .bubble-third {
        -webkit-transform: translateX(-120%);
                transform: translateX(-120%);
        bottom: -400px;
      }
    }

.IndexContainerPnp .Features,
  .IndexContainerPnp .Carousel {
    grid-gap: 80px;
    gap: 80px;
  }

.IndexContainerPnp .Features .feature, .IndexContainerPnp .Carousel .feature {
      background: linear-gradient(#ffffff9c, #0364779c);
      background-origin: border-box;
      width: 200px;
      border-radius: 20px;
      border: solid 3px #ffffff3d;
      padding: 10px;
    }

.IndexContainerPnp .Features .feature img, .IndexContainerPnp .Carousel .feature img {
        height: 130px;
        margin-top: -50px;
      }

.IndexContainerPnp .Features .feature .feature-content > :first-child, .IndexContainerPnp .Carousel .feature .feature-content > :first-child {
          margin: 4px;
          text-transform: uppercase;
          font-weight: normal;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }

.IndexContainerPnp .Features .feature .feature-content p, .IndexContainerPnp .Carousel .feature .feature-content p {
          margin: 0;
          font-size: 12px;
        }

@media (max-width: 728px) 
    {
      .IndexContainerPnp .Features .feature, .IndexContainerPnp .Carousel .feature {
        padding: 50px 0;
      }
    }

.IndexContainerPnp .main-content-container {
    background-image: none;
    background-size: auto;
    background-repeat: repeat-x;
  }

.IndexContainerPnp .main-content-container .index__header .index__header__logo {
        display: none;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: relative;
      }

.IndexContainerPnp .main-content-container .index__header .index__header__logo::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background: url('../images/cancel-white.svg') no-repeat center;
          background-size: 18px;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__logo:hover {
          background: lightgray;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__logo img {
          display: none;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home,
        .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu {
          background: #ffffff;
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          height: 40px;
          width: 40px;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home img, .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu img {
            position: relative;
            width: 35px;
            height: 35px;
            padding: 5px;
            top: 2px;
            left: 2px;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home:hover, .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu:hover {
            background: gray;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home {
          display: none;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu {
          display: initial;
          background: #ffffff;
          border-radius: 50%;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu img {
            width: 35px;
            margin-left: 1px;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu:hover {
            background: lightgray;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in {
          display: initial;
          margin-right: 20px;
        }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in .button.secondary {
            background: #2ca6ff;
            box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
            width: 120px;
            height: 40px;
            color: #ffffff;
            font-size: 13px;
            font-weight: normal;
            text-align: center;
            text-decoration: none;
            text-transform: capitalize;
            line-height: 42px;
            padding: 0;
          }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in .button.secondary span {
              display: inline;
            }

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in .button.secondary:hover {
              background: #2284cb;
            }

@media (max-width: 992px) {

.IndexContainerPnp .main-content-container .index__header .index__header__actions .sign-in {
            margin-right: 0px
        }
          }

.IndexContainerPnp .main-content-container .brand-description + .actions .button {
        margin-top: 0;
      }

.IndexContainerPnp .main-content-container .brand-description {
      padding: 0;
    }

.IndexContainerPnp .main-content-container .brand-description p:first-child {
        margin: 0;
      }

.IndexContainerPnp .main-content-container .brand-description p img {
          width: 250px;
        }

.IndexContainerPnp .main-content-container .brand-description h2 {
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        margin: 0;
      }

@media (max-width: 530px) {
        .IndexContainerPnp .main-content-container .brand-description img {
          width: 160px !important;
          margin-top: 30px;
        }
  }

@media (max-width: 320px) {
    .IndexContainerPnp .main-content-container .brand-description img {
      width: 135px !important;
    }
  }

.IndexContainerPnp .seo-body {
    position: relative;
    height: 100%;
    padding: 0;
  }

.IndexContainerPnp .seo-body .seo-body-content {
      width: 80%;
      margin: 0 auto;
    }

@media (max-width: 992px) {

.IndexContainerPnp .seo-body .seo-body-content {
        width: 100%
    }
      }

.IndexContainerPnp .index-container-full {
    padding: 0;
  }

.IndexContainerPnp .index-container-full .seo-header {
      position: unset;
      width: 100%;
      padding:0 !important;
    }

.IndexContainerPnp .index-container-full .seo-header .seo-arrow {
        display: none;
      }

.IndexContainerPnp .index-container-full .seo-header .seo-header-content {
        padding: 0 15px 0 15px;
      }

body[data-pnp-step] .index__header__logo {
  display: block !important;
}

.Dashboard {
  padding: 0 10px;
}

.Dashboard .balance {
    display: none;
  }

.Dashboard .membership-buttons {
    margin-bottom: 30px;
  }

.Dashboard .ItemWithIconAndText,
  .Dashboard .support .button.primary {
    background: none;
    border-radius: 4px;
    box-shadow: none;
    background-color: #ffffff;
    display: flex;
    flex-flow: wrap;
    margin: 0;
    width: 100%;
    padding: 10px;
    font-weight: normal;
    text-transform: lowercase;
    font-size: 16px;
    line-height: initial;
    min-height: 70px;
  }

.Dashboard .ItemWithIconAndText .text, .Dashboard .support .button.primary .text {
      color: #414141;
      margin-top: 0;
      margin-left: 20px;
    }

.Dashboard .ItemWithIconAndText:after, .Dashboard .support .button.primary:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 20px;
      height: 40px;
      width: 20px;
      background-image: url('../images/menu-arrow.svg');
      background-repeat: no-repeat;
      background-size: 28px;
    }

.Dashboard .ItemWithIconAndText .icon, .Dashboard .support .button.primary .icon {
      width: 35px;
      height: 35px;
    }

.Dashboard .products {
    margin-bottom: 10px;
    flex-flow: column;
    order: 1;
  }

.Dashboard .profile-links {
    order: 2;
  }

.Dashboard .products a, .Dashboard .profile-links a {
      border-radius: 0;
    }

.Dashboard .products a:first-child, .Dashboard .profile-links a:first-child {
      border-radius: 8px 8px 0 0;
    }

.Dashboard .products a:last-child, .Dashboard .profile-links a:last-child {
      border-radius: 0 0 8px 8px;
      box-shadow: 0px 4px 5px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
    }

.Dashboard .products a:only-child, .Dashboard .profile-links a:only-child {
      border-radius: 8px;
    }

.Dashboard .support {
    padding: 0;
    order: 3;
    margin-top: 20px;
  }

.Dashboard .support .button.primary {
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

.Dashboard .support .button.primary:after {
        top: 22px;
        right: 20px;
      }

.Dashboard .support span {
      line-height: 50px;
      margin-top: 0;
      margin-left: 20px;
      padding-left: 34px;
      position: relative;
      color: #414141;
      font-weight: normal;
    }

.Dashboard .support span:before {
        position: absolute;
        left: -22px;
        top: 8px;
        content: '';
        width: 35px;
        height: 35px;
        background-image: url('../images/support.svg');
        background-size: contain;
      }

.Dashboard .navigation {
    order: 4;
    display: flex;
    flex-flow: column;
    font-size: 1.2em;
    margin-top: 20px;
    line-height: 35px;
    color: #414141;
  }

.Dashboard .navigation a {
      font-weight: normal;
      text-decoration: none;
    }

.Dashboard .payment-buttons {
    align-self: center;
    margin-top: 25px;
    font-size: 1.2em;
    width: 450px;
    order: 5;
  }

.Dashboard .payment-buttons a.button.tertiary.wide {
      background: #ffffff;
      color: #414141;
    }

.Dashboard .payment-buttons a.button.tertiary.wide:hover {
        background-color: #eeeeee;
      }

.Dashboard .payment-buttons .button {
      margin: 0 5px;
    }

.Dashboard .footer {
    position: relative;
    order: 6;
    flex-grow: 1;
    align-items: baseline;
    padding: 0 10px;
  }

.Dashboard .footer a.button.tertiary {
      width: 120px;
      margin-right: 5px;
      background: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      font-weight: normal !important;
      text-transform: initial;
      text-decoration: underline;
      line-height: unset;
      display: flex;
      justify-content: center;
      padding: 10px;
    }

.Dashboard .footer .LanguageSelector {
      background: #ffffff;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      margin-bottom: 30px;
      margin-top: 20px;
      font-weight: normal;
    }

.Dashboard .footer .LanguageSelector__label {
        color: #414141;
        padding-left: 0;
        justify-content: space-between;
        text-transform: lowercase;
      }

.Dashboard .footer .LanguageSelector__label img {
          width: 30px;
          height: 30px;
          margin-left: 7px;
        }

.Dashboard .footer .LanguageSelector__label .select-icon {
          margin-right: 10px;
        }

.BonusContainer .AccordionWidget .AccordionItem--expanded .AccordionItem__header {
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

.BonusContainer .AccordionWidget .AccordionItem--expanded .AccordionItem__content {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

.BasePage {
  overflow: hidden;
}

.BasePage .main-content-container {
    background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

.BasePage .main-content-container .promotions-grid {
      justify-content: center;
    }

@media (max-width: 992px) {

.BasePage .main-content-container .promotions-grid {
        width: 100%
    }
      }

.BasePage .slide-item {
    width: 18.5vw !important;
  }

@media (max-width: 992px) {

.BasePage .slide-item {
      width: 25.5vw !important
  }
    }

@media (max-width: 502px) {

.BasePage .slide-item {
      width: 50.5vw !important
  }
    }

.BasePage .category-row {
    margin-bottom: 80px;
  }

@media (max-width: 992px) {

.BasePage .category-row {
      margin-bottom: 20px
  }
    }

.BasePage .category-row .slider .list .arrow {
          width: 4.2vw;
          top: 35%;
          height: 4.5vw;
        }

@media (max-width: 1229px) {
          .BasePage .category-row .slider .list .arrow {
            width: 3.6vw;
          }
      }

.BasePage .category-row .slide-item {
      width: auto !important;
    }

.BasePage .slider .slide-item .last-slide {
        align-items: start !important;
      }

.BasePage .slider .slide-item .last-slide a {
          border-radius: 4px;
          height: 10vw;
        }

.BasePage .slider .items {
      max-height: unset;  
    }

.BasePage .slider .track {
      overflow: unset;
    }

.BasePage .slider .arrow--show {
      background-color: #42d19d;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      color: #ffffff;
      width: 3.6vw;
      opacity: 1;
      height: 4.2vw;
      top: 23%;
    }

.BasePage .slider .arrow--show i {
        font-size: 25px;
      }

.BasePage .slider .arrow--next {
      border-radius: 100% 0 0 100%;
    }

.BasePage .slider .arrow--prev {
      border-radius: 0 100% 100% 0;
    }

@media (max-width: 992px) {
          .BasePage .slider .slide-item .last-slide a {
            height: 14.2vw;
          }

      .BasePage .slider .arrow--show {
        width: 36px !important;
      }
    }

@media (max-width: 502px) {
      .BasePage .slider .arrow--show {
        height: 10.5vw;
      }
          .BasePage .slider .slide-item .last-slide a {
            height: 28.5vw;
          }
    }

@media (max-width: 1020px) {
      .BasePage .slider .list {
        width: 100%;
      }
    }

.BasePage .no-last-slide {
    height: 18.05vw;
  }

@media (max-width: 992px) {

.BasePage .no-last-slide {
      height: 290px
  }

      .BasePage .no-last-slide .CasinoCategoryRow {
        height: 290px;
      }
    }

.BasePage .no-last-slide .thumbnail {
      width: 18.05vw;
      height: 18.05vw;
    }

@media (max-width: 992px) {

.BasePage .no-last-slide .thumbnail {
        width: 250px;
        height: 250px
    }
      }

.CasinoContainer .main-content-container .content {
      padding-top: 20px;
    }

.CasinoContainer .main-content-container .Banners {
      height: 35vh;
      min-height: 600px;
    }

@media (max-width: 992px) {

.CasinoContainer .main-content-container .Banners {
        height: 35vh;
        min-height: unset
    }
      }

@media (max-width: 750px) {

.CasinoContainer .main-content-container .Banners {
        height: 40vh
    }
      }

.CasinoContainer .main-content-container .Banners .background {
        background-position: center !important;
      }

.CasinoContainer .main-content-container .Banners .content {
        bottom: 0 !important;
        top: 50%;
        right: initial;
        padding: 0 50px 30px;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        -webkit-transform: TranslateY(-50%) !important;
                transform: TranslateY(-50%) !important;
      }

@media (max-width: 992px)
        {

.CasinoContainer .main-content-container .Banners .content {
          top: 0;
          left: 0 !important;
          -webkit-transform: TranslateY(0%) !important;
                  transform: TranslateY(0%) !important;
          padding: 0 30px 20px;
          margin: 0 !important;
          font-size: 0.9em
      }
          .CasinoContainer .main-content-container .Banners .content .button {
            text-transform: uppercase;
          }

        }

.CasinoContainer .main-content-container .Banners .content .background {
          background-position: center center;
        }

.CasinoContainer .main-content-container .Banners .content .content {
          left: 40px !important;
          width: 100%;
          max-width: 1900px;
          padding: 0 20px;
          position: relative;
          margin-bottom: 60px;
          /* reset styles from tg-base */
          top: auto !important;
          bottom: auto !important;
        }

.CasinoContainer .main-content-container .Banners .content .content h1 {
            font-size: 35px;
          }

.CasinoContainer .main-content-container .Banners .content .content h1,
          .CasinoContainer .main-content-container .Banners .content .content h2,
          .CasinoContainer .main-content-container .Banners .content .content h3,
          .CasinoContainer .main-content-container .Banners .content .content h4 {
            margin: 0;
            line-height: normal;
          }

@media (max-width: 992px) {

.CasinoContainer .main-content-container .Banners .content .content {
            left: 20px !important
        }
          }

@media (max-width: 992px)
      {

.CasinoContainer .main-content-container .Banners {
        min-height: unset
    }
      }

.ContentContainer .actual-content {
    font-size: 15px;
    line-height:25px;
  }

.ContentContainer .actual-content h1,
    .ContentContainer .actual-content h2,
    .ContentContainer .actual-content h3,
    .ContentContainer .actual-content h4,
    .ContentContainer .actual-content h5,
    .ContentContainer .actual-content h6 {
      font-size: 18px;
      color: #414141 !important;
      font-weight: normal !important;
    }

.ContentContainer .actual-content strong {
      font-weight: normal;
      font-size: 20px;
      line-height: 40px;
    }

.ContentContainer .main-content-container[style] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

.ContentContainer main.container {
    color: #414141;
    line-height:20px;
  }

.ContentContainer main.container .content-container {
      padding: 30px 30px 0;
    }

.ContentContainer main.container .content-container .NavList {
        margin-right: 20px;
      }

.ContentContainer main.container .content-container .NavList ul {
          box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
          border-radius: 10px;
        }

.ContentContainer main.container .content-container .NavList ul a {
            opacity:1;
            font-size:15px;
            color: #414141;
          }

.ContentContainer main.container .content-container .NavList ul .active {
            background-color: #eeeeee;
          }

.ContentContainer main.container .content-container .NavList ul :hover {
            background-color: #eeeeee;
          }

.responsible-gaming header p {
      margin-top: 0;
      margin-bottom: 20px;
    }

.newsletter-container {
  color: #414141;
  text-align: center;
  font-weight: normal;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.newsletter-container h1 {
    color: #414141;
    text-transform: capitalize !important;
    font-weight: normal;
  }

.newsletter-container p {
    font-weight: normal !important;
  }

.newsletter-container .newsletter-buttons {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin: 0 auto;
  }

.newsletter-container .newsletter-buttons .subscribe-item {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

.newsletter-container .newsletter-buttons .subscribe-item label {
        text-transform: inherit;
        width: 235px;
      }

.newsletter-container .newsletter-buttons .subscribe-item:last-child {
        margin-bottom: 0;
      }

.newsletter-container .Checkbox {
    display: block;
    margin-bottom: 15px;
  }

.newsletter-container .Checkbox__input-wrapper {
      position: absolute;
      margin-left: -50px;
    }

.newsletter-container .Checkbox__input {
      background-color: #ffffff;
      border: none;
      box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      color: #414141;
      width: 35px;
      height: 35px;
    }

.newsletter-container .Checkbox__input:checked {
        background-color: #ffffff;
        background-size: 1.4em;
        color: #414141;
      }

.newsletter-container .Checkbox__label {
      margin: 0;
      padding: 0;
    }

@media (max-width: 992px) {
    .newsletter-container .Checkbox {
      margin-right: 20px;
    }
      .newsletter-container .Checkbox__input-wrapper {
        position: relative;
        margin-left: -10px;
      }
  }

.Activate .form {
    margin: 0 auto;
  }

.Activate button {
    background: #3edb94;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #ffffff;
    transition: none;
  }

.Activate button:hover {
      background: #3bb98b;
      color: #ffffff;
    }

.Activate .resend {
    text-decoration: underline;
  }

.SourceOfWealthContainer .checkbox label {
    margin-left: 0;
  }

.SourceOfWealthContainer .other textarea {
      border: 1px solid #2ca6ff;
      background: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
      color: #ffffff;
      border-radius: var(--boder-radius);
      font-size: 14px;
      font-style: italic;
    }

.SourceOfWealthContainer .other textarea::-webkit-input-placeholder {
        color: #ffffff;
      }

.SourceOfWealthContainer .other textarea::placeholder {
        color: #ffffff;
      }

.SourceOfWealthContainer .income .checkbox label {
      width: 80%;
    }

@media (max-width: 992px) {
    .SourceOfWealthContainer .button-wrapper {
      flex-direction: column;
    }

      .SourceOfWealthContainer .button-wrapper .button {
        width: 100%;
        margin: 0;
      }

        .SourceOfWealthContainer .button-wrapper .button:nth-child(2) {
          margin-top: 10px;
        }
  }

.SourceOfWealthContainer hr {
    border: none;
    height: 1px;
    background: #ffffff;
  }

.WithdrawContainer .balance-info {
    text-transform: uppercase;
  }

.SportsContainer {
  background: #00020a;
}

@media (max-width: 992px) {
    .SportsContainer .BetslipTab {
      -webkit-transform: translate(-50%, 0%);
              transform: translate(-50%, 0%);
      text-decoration: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      max-width: calc(100% - 100px);
      bottom: calc(env(safe-area-inset-bottom) + 69px);
      left: 50%;
    }

      .SportsContainer .BetslipTab > .icon:first-child {
        padding-top: 9px;
      }
  }
