h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--white);
}

.Popup__content h1,
.Popup__content h2,
.Popup__content h3,
.Popup__content h4,
.Popup__content h5,
.Popup__content h6,
.Popup__content .h1,
.Popup__content .h2,
.Popup__content .h3,
.Popup__content .h4,
.Popup__content .h5,
.Popup__content .h6 {
  color: #414141;
}

body {
  &.is-offcanvas-open {
    #fc_frame:not(.fc-open) {
      display: none;
    }
  }
}

#fc_frame:not(.fc-open) {
  display: initial;

  @media (max-width: 768px) {
    display: none;
  }
}

#launcher {
  @media (max-width: 768px) {
    display: none;
  }
}

@keyframes mermaid-notify {
  from {
    right: 0px;
  }
  to {
    right: -500px;
  }
}

@keyframes mermaid-side {
  from {
    right: -400px
  }
  to {
    right: -70px;
  }
}

@keyframes chat-bubble {
  from {
    transform: TranslateY(-230%) Scale(0%);
  }
  to {
    transform: TranslateY(-230%) Scale(100%);
  }
}

@keyframes mermaid-bottom {
  from {
    bottom: -250px;
  }
  to {
    bottom: -20px;
  }
}

@keyframes bubble-first {
  from {
    bottom: -400px
  }
  to {
    bottom: 140px;
  }
}

@keyframes bubble-second {
  from {
    bottom: -400px
  }
  to {
    bottom: 300px;
  }
}

@keyframes bubble-third {
  from {
    bottom: -400px
  }
  to {
    bottom: 200px;
  }
}

@keyframes shine {
  from {
    transform: translateX(-160px) skewX(15deg);
  }

  to {
    transform: translateX(2000px) skewX(15deg);
  }
}

