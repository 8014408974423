@import './colors.css';
@import './variables.css';

.Game {
  overflow: hidden;
  border-radius: 6px;

  .jackpot {
    color: var(--loading-spinner);
    bottom: unset;
    z-index: 2;
    padding-top: 5px;

    span {
      line-height: 20px;
    }
  }

  .overlay {
    border-radius: 4px;

    @media (max-width: 992px) {
      height: 14.5vw !important;
    }

    @media (max-width: 502px) {
      height: 28.5vw !important;
    }
  }

  .favorite-heart {
    display: none;
    bottom: 10px;
    top: initial;
    z-index: 10;
    width: 24px;
    height: 24px;

    .icon-heart-o,
    .icon-heart {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0px;
        right: 0;
      }
    }

    .icon-heart-o {
      &:before {
        color: darkgray;
        background-image: url('../images/favourited-disabled.svg');
      }
    }

    .icon-heart {
      &:before {
        color: var(--white);
        background-image: url('../images/favourited-enabled.svg');
      }
    }
  }

  .thumbnail {
    border-radius: var(--border-radius);
    z-index: 0;

    .badges {
      top: 5px;
      bottom: initial;
      flex-wrap: wrap;

      .badge {
        margin-bottom: 5px;
      }
    }

    .badges .badge,
    .ribbon {
      background: rgba(0, 25, 60, 0.88);
    }

    .ribbon + .badges {
      flex-direction: column;
    }
  }

  .game-info {
    display: flex;
    flex-flow: column-reverse;
    position: relative;
    height: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;

    .title {
      color: var(--black);
      margin-bottom: 5px;
      font-weight: normal;
      order: 2;
    }

    .provider {
      color: var(--light-blue);
      order: 1;
    }

    .live-casino-info {
      .wager-limits {
        color: #434343;
        font-weight: normal;
      }

      .roulette-numbers {
        font-weigth: normal;
      }

      .blackjack-info {
        color: #434343;
      }
    }
  }

  &__favorite-heart {
    display: none;
  }

  &:hover {
    .Game__favorite-heart {
      display: block;
      .FavoriteHeart {
        .icon-heart-o {
          color: var(--black);
          opacity: 0.3;
          font-size: 30px;
          text-shadow: var(--text-shadow);
        }
        .icon-heart {
          color: var(--white);
          font-size: 30px;
          text-shadow: var(--text-shadow);
        }
      }
    }

    .game-info {
      &:before {
        display: block;
      }
    }

    .thumbnail {
      background-image: url('../images/play-background.png');
      background-size: 350px;
      background-position: center;
      box-shadow: var(--box-shadow);

      .jackpot {
        img {
          opacity: 1;
        }
      }

      img {
        opacity: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .game-info {
      display: flex;
      position: relative;
      padding: 10px;
      height: auto;

      &:before {
        display: none;
      }
    }

    .favorite-heart {
      position: absolute;
      display: block;
      bottom: 5px;
    }

    .thumbnail {
      &:after {
        display: none;
      }
    }

    &:hover {
      .game-info {
        &:before {
          display: none;
        }
      }
    }
  }
}
