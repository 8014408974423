@font-face {
  font-family: Baloo;
  src: url('/skin/fonts/Baloo-Regular.ttf') format('truetype');
}

.OffCanvas,
.WelcomeBonus,
.content h3,
body,
.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
nav.categories a,
.casino-categories-item,
.casino-categories-item-total,
.tg-input .Input input,
select,
.Promotion .content h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Baloo', 'Fira Sans', sans-serif;
}
