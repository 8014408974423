.BasePage {
  overflow: hidden;
  .main-content-container {
    background: var(--main-background);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    .promotions-grid {
      justify-content: center;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .slide-item {
    width: 18.5vw !important;

    @media (max-width: 992px) {
      width: 25.5vw !important;
    }

    @media (max-width: 502px) {
      width: 50.5vw !important;
    }
  }

  .category-row {
    margin-bottom: 80px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }

    .slider {
      .list {
        .arrow {
          width: 4.2vw;
          top: 35%;
          height: 4.5vw;
        }
      }

      @media (max-width: 1229px) {
        .list {
          .arrow {
            width: 3.6vw;
          }
        }
      }
    }

    .slide-item {
      width: auto !important;
    }
  }

  .slider {
    .slide-item {
      .last-slide {
        align-items: start !important;
        a {
          border-radius: var(--border-radius);
          height: 10vw;
        }
      }
    }

    .items {
      max-height: unset;  
    }

    .track {
      overflow: unset;
    }

    .arrow--show {
      background-color: var(--primary-btn-color);
      box-shadow: var(--box-shadow);
      color: var(--white);
      width: 3.6vw;
      opacity: 1;
      height: 4.2vw;
      top: 23%;

      i {
        font-size: 25px;
      }
    }

    .arrow--next {
      border-radius: 100% 0 0 100%;
    }

    .arrow--prev {
      border-radius: 0 100% 100% 0;
    }

    @media (max-width: 992px) {
      .slide-item {
        .last-slide {
          a {
            height: 14.2vw;
          }
        }
      }

      .arrow--show {
        width: 36px !important;
      }
    }

    @media (max-width: 502px) {
      .arrow--show {
        height: 10.5vw;
      }

      .slide-item {
        .last-slide {
          a {
            height: 28.5vw;
          }
        }
      }
    }

    @media (max-width: 1020px) {
      .list {
        width: 100%;
      }
    }
  }

  .no-last-slide {
    height: 18.05vw;

    @media (max-width: 992px) {
      height: 290px;

      .CasinoCategoryRow {
        height: 290px;
      }
    }

    .thumbnail {
      width: 18.05vw;
      height: 18.05vw;

      @media (max-width: 992px) {
        width: 250px;
        height: 250px;
      }
    }
  }
}
