@import './colors';
@import './variables.css';

.ResponsibleGamingWidget,
.HistoryContainer,
.cashier-accordion {
  .AccordionWidget {
    .AccordionItem {
      .AccordionItem__header {
        box-shadow: none;
        margin-bottom: -10px;
        margin-top: 10px;
      }

      .AccordionItem__content {
        border-radius: 0px 0px 10px 10px !important;
        .navigation {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .button {
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.AccordionWidget {
  .AccordionItem {
    margin-top: 1px;
    color: var(--black);

    &:only-child {
      border-radius: var(--border-radius);
    }

    .AccordionItem__header {
      background-color: var(--secondary-btn-color);
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      margin-bottom: 10px;

      &:hover {
        background-color: var(--gray);
      }

      .responsiblegaming-item-header .info-amount,
      .responsiblegaming-item-header .title-text,
      .AccordionItem__expand-icon {
        color: var(--white);
      }
    }

    &--expanded {
      .AccordionItem__header {
        box-shadow: none;
        margin-bottom: -45px;

        &__tooltop-icon {
          top: 31px;
        }

        &:hover {
          background: var(--white);
        }
      }
    }

    .AccordionItem__header--expanded {
      box-shadow: 0;
    }

    .AccordionItem__content {
      background-color: var(--white);
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 1.1em;

      p {
        color: gray;
        font-size: 15px;
      }

      .ResponsibleGamingWidget {
        &__timespan-limit-selector {
          .tg-input {
            margin: 0 !important;

            .Input {
              background-color: var(--main-background);
              border-radius: 5px;
              input {
                font-size: 1rem;
                caret-color: var(--white);
                color: var(--black);
                font-weight: bold;
                padding-bottom: 8px;
                background: transparent;
              }

              &__status-line {
                background-color: var(--primary-btn-color);
              }
            }
          }

          .button {
            width: auto;
            padding: 0 2.5em;
            display: grid;
            place-items: center;
          }
        }

        &__timespan-title {
          font-weight: normal;
        }
      }

      .payment-method-item-content {
        .custom-amount-and-submit {
          .tg-input {
            .Input {
              &__status-line {
                display: none;
              }

              label {
                color: var(--black);
              }
            }
          }

          button {
            background: var(--primary-btn-color);
            border-radius: 0 5px 5px 0;
          }

          .error-message {
            color: var(--error);
            font-size: 13px;
            left: 0;
          }
        }
        .account-selection {
          background: inherit;
          .tg-select .Select {
            select {
              background: var(--white);
              border-radius: 4px;
              color: var(--black);
            }
          }
        }
      }
    }
  }
}
