@import './colors.css';

.StickyFooter-mobile {
  background: var(--white);
  box-shadow: var(--box-shadow);
  width: 295px;
  max-width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  bottom: env(safe-area-inset-bottom);
  border-radius: 27px;
  overflow: hidden;
  padding: 0 20px 0 20px;
  margin: 15px auto;

  a {
    justify-content: center;
    font-weight: 50;
    letter-spacing: 0.4px;
    color: var(--black);

    .icon {
      height: 26px;
      width: 26px;
      margin-bottom: 5px;
    }

    img {
      pointer-events: none;

      & + div {
        font-size: 10px;
      }
    }

    &.exit-sidebar-icon,
    &.hamburger-icon {
      img {
        height: 26px;
        width: 26px;
        margin-bottom: 5px;
      }
    }

    &.active {
      background: #eeeeee;

      img {
        filter: invert(0);
      }
    }
  }

  .notifications-badge--right[data-notifications]:not([data-notifications='']):not([data-notifications='0'])::after {
    top: 8px;
    right: 8px;
  }
}
