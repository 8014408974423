.responsiblegaming-signup,
.responsiblegaming-limit-selector
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container {
  .AmountItem {
    margin: 10px;
  }

  .custom-amount-increment,
  .custom-amount-decrement {
    color: var(--white) !important;
    background: var(--primary-btn-gradient) !important;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    &:hover {
      background: var(--primary-btn-background-hover) !important;
    }
  }

  .custom-amount-input .Input {
    input[type='number'] {
      padding-top: 13px;
      background: var(--white) !important;
      color: black !important;
      caret-color: var(--primary-color);

      &::placeholder {
        color: #474747 !important;
      }
    }
  }

  .limit-info-container {
    text-align: center;
    text-transform: uppercase;
  }

  .custom-amount .error-message {
    span {
      color: black;
    }
  }

  .timespan-label {
    color: var(--white);
    font-weight: bold;
  }

  .timespan-selector {
    .button {
      background: none !important;
      box-shadow: none !important;
      font-weight: normal !important;
      font-size: 14px !important;
      padding: 2px;

      &.selected {
        background: none !important;
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 540px) {
        font-size: 12px !important;
      }
    }
  }
}

.pending-container {
  padding: 20px;

  button {
    margin-top: 10px;
  }
}

.PayNPlay {
  .limit-info-container,
  .notice,
  .Prompt h2 {
    color: var(--white);
    flex-direction: unset;
  }
}
