.SuccessfulDepositContainer {
  .Popup {
      &__children {
        color: var(--white);

        h2 {
        color: var(--white);
      }

      img {
        max-width: 180px;
      }
    }
  }
}

.successful-deposit.popup {
  .popup-content {
    h2 {
      text-transform: uppercase;
    }

    > div {
      background: none;
    }
  }
}
