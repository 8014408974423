@import '../skin/theme/colors.css';

$transition-time: 0.17s;

.AccordionWidget {
  .AccordionItem {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 5px;

    &__header {
      position: relative;
      text-align: left;
      padding: 8px 15px;
      min-height: 68px;
      cursor: pointer;
      background: var(--dark-gray);
      user-select: none;
      font-size: 15px;
      transition: background $transition-time;

      &:hover {
        background: rgba(var(--dark-gray), 0.9);
      }

      .title-text,
      span {
        line-height: 52px;
      }

      .info-amount {
        display: block;
        font-size: 16px;
        position: absolute;
        top: 8px;
        right: 55px;
        font-weight: 400;
        color: var(--gray);

        @media (max-width: 375px) {
          font-size: 11px;
          right: 40px;
        }
      }

      &__expand-icon {
        position: absolute;
        right: 20px;
        top: 10px;
        pointer-events: none;
        font-size: 36px;
        transform: scale(0.55);
        transform-origin: center;
        transition: transform $transition-time;
        color: var(--darker-gray);
      }

      &__tooltop-icon {
        display: none;
        font-size: 16px;
        position: absolute;
        top: 24px;
        right: 55px;

        &:hover {
          opacity: 0.8;
        }
      }

      &__tooltop {
        display: none;
        background: var(--white);
        color: var(--black);
        flex-basis: 100%;
        padding: 1px 15px;
        border-radius: 2px;
        &::before {
          content: '';
          position: absolute;
          top: 45px;
          right: 54px;
          margin-left: -5px;
          border-width: 8px;
          border-style: solid;
          border-color: transparent transparent var(--white) transparent;
        }

        @media (max-width: 375px) {
          font-size: 12px;
        }
      }
    }

    &__content {
      background: var(--dark-gray);

      > div {
        padding: 8px 15px;
      }
      .no-list-data {
        padding: 10px 0 2px 0;
      }
    }

    &--expanded {
      .AccordionItem__header__expand-icon {
        transform: scale(0.65) rotate(180deg);
      }

      .AccordionItem__header__tooltop-icon,
      .AccordionItem__header__tooltop {
        display: block;
      }

      .info-amount {
        display: none;
      }
    }
  }
}
