@import '../colors.css';

.newsletter-container {
  color: var(--black);
  text-align: center;
  font-weight: normal;
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  h1 {
    color: var(--black);
    text-transform: capitalize !important;
    font-weight: normal;
  }

  p {
    font-weight: normal !important;
  }

  .newsletter-buttons {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin: 0 auto;

    .subscribe-item {
      flex-direction: row-reverse;
      justify-content: space-between;

      label {
        text-transform: inherit;
        width: 235px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .Checkbox {
    display: block;
    margin-bottom: 15px;
    &__input-wrapper {
      position: absolute;
      margin-left: -50px;
    }

    &__input {
      background-color: var(--white);
      border: none;
      box-shadow: var(--box-shadow);
      color: var(--black);
      width: 35px;
      height: 35px;

      &:checked {
        background-color: var(--white);
        background-size: 1.4em;
        color: var(--black);
      }
    }

    &__label {
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 992px) {
    .Checkbox {
      margin-right: 20px;
      &__input-wrapper {
        position: relative;
        margin-left: -10px;
      }
    }
  }
}
