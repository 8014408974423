.Promotion {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  background: var(--primary-color);
  width: calc(100% / 3 - 40px);

  img {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 100%;
  }

  .promotion-content-wrapper {
    padding: 15px;

    .title {
      font-weight: 700;
      font-size: 1.4em;
    }
  }
}

.PromotionDetailContainer {
  .promotion-banner {
    position: relative;
  }

  .banner-content {
    position: absolute;
    background: transparent !important;
    top: 50%;
    transform: TranslateY(-50%);
    left: 10%;

    @media (max-width: 992px)
    {
      left: 0px;
    }
  }

  .banner-image {
    height: 35vh;
    min-height: 600px;
    background-position: bottom right !important;

    @media (max-width: 992px) {
      height: 35vh;
      min-height: unset;
      background-position: center !important;
    }

    @media (max-width: 750px) {
      height: 40vh;
    }
  }

  .promotion-content {
    border-radius: var(--border-radius);
    color: var(--black);
    padding: 20px;
    margin: 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--black);

      &:first-child {
        margin-top: 0;
      }
    }

    @media (min-width: 992px) {
      width: auto;
    }
  }
}
