.SportsContainer {
  background: #00020a;
  @media (max-width: 992px) {
    .BetslipTab {
      transform: translate(-50%, 0%);
      text-decoration: none;
      border-top-left-radius: var(--border-radius-big);
      border-top-right-radius: var(--border-radius-big);
      max-width: calc(100% - 100px);
      bottom: calc(env(safe-area-inset-bottom) + 69px);
      left: 50%;

      > .icon:first-child {
        padding-top: 9px;
      }
    }
  }
}
