.rg-wrapper {
  font-style: italic;

  .SessionClock {
    position: relative;
    padding-top: 3px;

    i.icon-live-sports {
      margin-right: 18px;

      &:before {
        content: '';
        background-image: url('../images/clock.svg');
        width: 14px;
        height: 14px;
        position: absolute;
        top: 4.5px;
      }
    }
  }
}
