@import './colors.css';

.game-history-wrapper {
  background-color: transparent;
  font-style: italic;

  .game-history-logo {
    filter: none;
    height: 45px;
    margin: 0 20px;
  }

  .dim-btn {
    width: 24px;
  }

  .dim-btn.on {
    .lightbulb-fill {
      fill: var(--primary-color);
    }
  }

  .game-history-chat {
    color: var(--primary-color);
    stroke: var(--primary-color);
    margin-right: 10px;
  }
}
