@import '../colors.css';

.ContentContainer {
  .actual-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px;
      color: var(--black) !important;
      font-weight: normal !important;
    }
    font-size: 15px;
    line-height:25px;

    strong {
      font-weight: normal;
      font-size: 20px;
      line-height: 40px;
    }
  }

  .main-content-container[style] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  main.container {
    color: var(--black);
    line-height:20px;
    .content-container {
      padding: 30px 30px 0;

      .NavList {
        margin-right: 20px;
        

        ul {
          box-shadow: var(--box-shadow);
          background-color: var(--white);
          border-radius: 10px;

          a {
            opacity:1;
            font-size:15px;
            color: var(--black);
          }

          .active {
            background-color: var(--gray);
          }

          :hover {
            background-color: var(--gray);
          }
        }
      }
    }
  }
}
