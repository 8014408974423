.CasinoCategoryRow {
  width: auto !important;
  height: 18.05vw;

  .overlay {
    display: none;
  }
  .category-name {
    bottom: 0;
    top: 100%;

    .title {
      font-size: 18px;
      font-weight: normal;
      color: #434343;
    }
  }
}
