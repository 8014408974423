.Activate {
  .form {
    margin: 0 auto;
  }

  button {
    background: var(--primary-btn-gradient);
    box-shadow: var(--box-shadow);
    color: var(--white);
    transition: none;

    &:hover {
      background: var(--primary-btn-background-hover);
      color: var(--white);
    }
  }

  .resend {
    text-decoration: underline;
  }
}
