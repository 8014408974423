@import '../colors.css';
@import '../variables.css';

.Dashboard {
  padding: 0 10px;
  .balance {
    display: none;
  }

  .membership-buttons {
    margin-bottom: 30px;
  }

  .ItemWithIconAndText,
  .support .button.primary {
    background: none;
    border-radius: var(--border-radius);
    box-shadow: none;
    background-color: var(--secondary-btn-color);
    display: flex;
    flex-flow: wrap;
    margin: 0;
    width: 100%;
    padding: 10px;
    font-weight: normal;
    text-transform: lowercase;
    font-size: 16px;
    line-height: initial;
    min-height: 70px;

    .text {
      color: var(--black);
      margin-top: 0;
      margin-left: 20px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 20px;
      height: 40px;
      width: 20px;
      background-image: url('../images/menu-arrow.svg');
      background-repeat: no-repeat;
      background-size: 28px;
    }

    .icon {
      width: 35px;
      height: 35px;
    }
  }

  .products {
    margin-bottom: 10px;
    flex-flow: column;
    order: 1;
  }

  .profile-links {
    order: 2;
  }

  .products,
  .profile-links {
    a {
      border-radius: 0;
    }

    a:first-child {
      border-radius: 8px 8px 0 0;
    }

    a:last-child {
      border-radius: 0 0 8px 8px;
      box-shadow: 0px 4px 5px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
    }

    a:only-child {
      border-radius: 8px;
    }
  }

  .support {
    padding: 0;
    order: 3;
    margin-top: 20px;

    .button.primary {
      position: relative;
      border-radius: 8px;
      box-shadow: var(--box-shadow);

      &:after {
        top: 22px;
        right: 20px;
      }
    }

    span {
      line-height: 50px;
      margin-top: 0;
      margin-left: 20px;
      padding-left: 34px;
      position: relative;
      color: var(--black);
      font-weight: normal;

      &:before {
        position: absolute;
        left: -22px;
        top: 8px;
        content: '';
        width: 35px;
        height: 35px;
        background-image: url('../images/support.svg');
        background-size: contain;
      }
    }
  }

  .navigation {
    order: 4;
    display: flex;
    flex-flow: column;
    font-size: 1.2em;
    margin-top: 20px;
    line-height: 35px;
    color: var(--black);

    a {
      font-weight: normal;
      text-decoration: none;
    }
  }

  .payment-buttons {
    align-self: center;
    margin-top: 25px;
    font-size: 1.2em;
    width: 450px;
    order: 5;

    a.button.tertiary.wide {
      background: var(--white);
      color: var(--black);

      &:hover {
        background-color: var(--gray);
      }
    }

    .button {
      margin: 0 5px;
    }
  }

  .footer {
    position: relative;
    order: 6;
    flex-grow: 1;
    align-items: baseline;
    padding: 0 10px;

    a.button.tertiary {
      width: 120px;
      margin-right: 5px;
      background: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      font-weight: normal !important;
      text-transform: initial;
      text-decoration: underline;
      line-height: unset;
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    .LanguageSelector {
      background: var(--white);
      box-shadow: var(--box-shadow);
      border-radius: 8px;
      margin-bottom: 30px;
      margin-top: 20px;
      font-weight: normal;

      &__label {
        color: var(--black);
        padding-left: 0;
        justify-content: space-between;
        text-transform: lowercase;
        img {
          width: 30px;
          height: 30px;
          margin-left: 7px;
        }

        .select-icon {
          margin-right: 10px;
        }
      }
    }
  }
}

.BonusContainer {
  .AccordionWidget {
    .AccordionItem {
      &--expanded {
        .AccordionItem__header {
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .AccordionItem__content {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
}
