@import './colors.css';
@import './variables.css';

input,
select {
  border: none;
  border-radius: var(--border-radius);
  background: var(--white);
  color: black;

  &::placeholder {
    color: #474747;
  }
}

input[type='file'] {
  background: none;
  color: inherit;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  min-width: 26px;
  height: 26px;
  background: var(--secondary-color);
  border-radius: var(--border-radius);
  border: 1px solid var(--tertiary-color);
  margin-right: 12px;
}

input[type='checkbox']:checked {
  background-image: url('../images/check.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1.5em;
  background-color: var(--secondary-color);
}

input[type='radio'] {
  background: var(--white);
  border: 2px solid var(--tertiary-color);
}

input[type='radio']:checked {
  border: 5px solid var(--tertiary-color);
  background: var(--white);
}

.Input {
  color: var(--black);
}

.tg-select .Select {
  .Select__status-line {
    display: none;
  }

  .Input__leading-lane,
  .Input__trailing-lane,
  .Select__leading-lane,
  .Select__trailing-lane {
    transform: translateY(-65%);
  }
}
