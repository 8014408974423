.Tournament {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  img {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
}

.TournamentDetailContainer {
  .tournament-banner .banner-content {
    background-color: var(--tertiary-color);
  }

  .tournament-content {
    color: var(--black);

    .column:first-child {
      padding: 20px;
      margin: 20px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--black);

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .leaderboard {
      background-color: var(--white);
      border-radius: var(--border-radius);
    }

    .leaderboard-header ~ .leaderboard {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .leaderboard-header {
      background-color: var(--tertiary-color);
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      color: var(--white);
      padding-bottom: 20px;
      font-weight: bold;
    }
  }

  .tournament-join {
    .button {
      transition: none;
    }
  }
}
