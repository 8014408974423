@import './colors.css';

.Footer {
  position: relative;
  background-color: var(--white);
  color: #7e7e7e;

  h3 {
    text-transform: none !important;
    color: #434343;
    font-weight: normal !important;
  }

  .topSection {
    background-color: var(--white);
    color: #434343;

    a {
      margin: 0 15px !important;
      font-size: 1.7em !important;
      font-weight: 500;
      text-transform: lowercase;
    }

    .LanguageSelector {
      box-shadow: var(--box-shadow);
      border-radius: 8px;
      margin-bottom: 30px;
      margin-top: 20px;
      font-weight: normal;

      &__label {
        padding-left: 0;
        justify-content: space-between;
        text-transform: lowercase;
        img {
          width: 30px;
          height: 30px;
          margin-left: 7px;
        }

        .select-icon {
          margin-right: 10px;
        }
      }
    }

    .container {
      margin-bottom: 10px;
      padding: 0 10px;
    }
  }

  .footer-logo {
    background-image: url('../images/logo.png');
    margin: 15px 0px;
    background-size: 70px 70px;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;

    img {
      display: none;
      height: 70px !important;
    }
  }

  .trademark {
    line-height: 15px;
  }

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 10vw;

    .three {
      order: -1;
    }
  }

  div.licence {
    flex-wrap: wrap;

    img {
      height: 44px !important;
      width: auto !important;
    }
  }

  .licence a {
    filter: brightness(2);
    margin: 10px;
  }
}
