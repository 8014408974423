.messages-list-item {
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);

  &:not(.open)&:hover {
    background-color: var(--tertiary-color);

    .message-wrapper::after {
      background: linear-gradient(transparent, var(--tertiary-color));
    }
  }

  .message-wrapper::after {
    background: linear-gradient(transparent, var(--tertiary-color));
  }
}

.messages-list-item header {
  margin-bottom: 0px !important;

  .title {
    &::after {
      background-color: var(--white);
      top: 9px;
    }
  }

  .time {
    color: var(--white);
  }
}
