@import './colors.css';

.CasinoContainer,
.JackpotDropContainer {
  .header-and-icon {
    > span {
      color: var(--black);
      font-weight: normal !important;
    }
  }

  .grid-header {
    a {
      color: var(--light-blue);
      font-weight: normal;
      text-decoration: none;
    }
  }

  .GameGrid:not(.search-grid) {
    .CasinoCategoryRow {
      .thumbnail {
        border-radius: 6px;
      }
    }

    .slide-item {
      .overlay {
        height: 10.2vw !important;
      }
    }

    .slick-arrow {
      &.slick-next {
        background: var(--primary-btn-background);

        &:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
        }
      }
      &.slick-prev {
        background: rgba(0, 25, 60, 0.88);

        &:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.GridContainer {
  .GameGrid {
    @media (min-width: 992px) {
      max-width: 96vw;
    }
  }

  .games.grid {
    .overlay {
      height: 12.8vw !important;
    }
  }

  .NoGamesMessage {
    color: gray;
  }
}
