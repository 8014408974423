.WinLossContainer {
  display: flex;
  justify-content: center;
}

.WinLossContainer .win-loss-section {
    padding: 10px 20px;
  }

.WinLossContainer .win-loss-section .win-loss-header {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
    }

.WinLossContainer .win-loss-section .win-loss-amount {
      font-size: 16px;
      font-weight: 500;
    }

.WinLossContainer .win-loss-section .win-loss-amount.is-positive {
        color: linear-gradient(to bottom, #f8ecd9 30%, #fae8d0 70%);
      }
