@import '../colors.css';
@import '../variables.css';

.IndexContainerPnp {
  .fixed-position {
    margin-top: 10px;
    padding-bottom: 100px;

    .actions {
      .button {
        padding: 0;
        width: 330px;
        text-transform: uppercase;
        position:relative;
        scale: 1;
        overflow: hidden;

        @media (max-width: 530px) {
          width: 250px;
          font-size: 20px;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.4);
          width: 10%;
          height: 100%;
          top: 0;
          left: -100px;
          filter: blur(10px);
          animation-name: shine;
          animation-delay: 2000ms;
          animation-duration: 2500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.60);
          width: 20%;
          height: 100%;
          top: 0;
          left: -100px;
          filter: blur(30px);
          animation-name: shine;
          animation-delay: 2000ms;
          animation-duration: 2500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }

    @media (max-width: 992px)
    {
      padding-bottom: 0;
    }
  }

  .index-background-active + .fixed-position {
    .Carousel,
    .Features,
    .brand-description h2 {
      display: none;
    }
  }

  .PayNPlayContainer {
    margin-top: 250px;

    @media (max-width: 992px) {
      margin-top: 80px;
    }
  }

  .brand-description + .PayNPlayContainer {
    margin-top: 0;
  }

  .mermaid-notify {
    position: absolute;
    height: 100%;
    width: 100%;
    top:  0;
    right: 0px;
    pointer-events: none;
    animation: mermaid-notify 3s 75s forwards;

    @media (max-width:728px)
    {
      display: none;
    }
  }


  .mermaid {
    position: absolute;
    right: -300px;
    top: 50%;
    transform: TranslateY(-150%);
    animation: mermaid-side 3s 2s forwards;
    img {
      width: 300px;
    }
  }

  .mermaid-bottom {
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: -250px;
    transform: TranslateX(-50%);
    animation: mermaid-bottom 3s 10s forwards;
    img {
      width: 300px;
    }

    @media (max-width:728px)
    {
      display: none;
    }
  }

  .chat-bubble {
    position: absolute;
    right: 180px;
    top: 50%;
    transform: TranslateY(-230%) Scale(0%);
    animation: chat-bubble 2s 4s forwards;

    img {
      width: 300px;
    }
  }

  .bubbles {
    height: 500px;
    img {
      position: absolute;
      width: 400px;
      left: 50%;
    }

    .bubble-first {
      transform: translateX(60%);
      bottom: -400px;
      animation: bubble-first 10s 3s forwards;
    }

    .bubble-second {
      transform: translateX(-40%);
      bottom: -400px;
      animation: bubble-second 10s 4s forwards;
    }

    .bubble-third {
      transform: translateX(-140%);
      bottom: -400px;
      animation: bubble-third 10s 2s forwards;
    }

    @media (max-width:728px)
    {
      height: 300px;

      img {
        width: 150px;
      }

      .bubble-first {
        transform: translateX(20%);
        bottom: -400px;
      }

      .bubble-second {
        transform: translateX(-40%);
        bottom: -400px;
      }

      .bubble-third {
        transform: translateX(-120%);
        bottom: -400px;
      }
    }
  }

  .Features,
  .Carousel {
    gap: 80px;
    .feature {
      background: linear-gradient(#ffffff9c, #0364779c);
      background-origin: border-box;
      width: 200px;
      border-radius: 20px;
      border: solid 3px #ffffff3d;
      padding: 10px;

      img {
        height: 130px;
        margin-top: -50px;
      }

      .feature-content {
        > :first-child {
          margin: 4px;
          text-transform: uppercase;
          font-weight: normal;
          text-shadow: var(--text-shadow);
        }

        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }

    @media (max-width: 728px) 
    {
      .feature {
        padding: 50px 0;
      }
    }
  }

  .main-content-container {
    background-image: none;
    background-size: auto;
    background-repeat: repeat-x;

    .index__header {
      .index__header__logo {
        display: none;
        background: var(--white);
        box-shadow: var(--box-shadow);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background: url('../images/cancel-white.svg') no-repeat center;
          background-size: 18px;
        }

        &:hover {
          background: lightgray;
        }

        img {
          display: none;
        }
      }

      .index__header__actions {
        .index__header__actions__home,
        .index__header__actions__menu {
          background: var(--white);
          box-shadow: var(--box-shadow);
          border-radius: 50%;
          height: 40px;
          width: 40px;

          img {
            position: relative;
            width: 35px;
            height: 35px;
            padding: 5px;
            top: 2px;
            left: 2px;
          }

          &:hover {
            background: gray;
          }
        }

        .index__header__actions__home {
          display: none;
        }

        .index__header__actions__menu {
          display: initial;
          background: var(--white);
          border-radius: 50%;

          img {
            width: 35px;
            margin-left: 1px;
          }

          &:hover {
            background: lightgray;
          }
        }

        .sign-in {
          display: initial;
          margin-right: 20px;

          .button.secondary {
            background: var(--light-blue);
            box-shadow: var(--box-shadow);
            width: 120px;
            height: 40px;
            color: var(--white);
            font-size: 13px;
            font-weight: normal;
            text-align: center;
            text-decoration: none;
            text-transform: capitalize;
            line-height: 42px;
            padding: 0;

            span {
              display: inline;
            }

            &:hover {
              background: #2284cb;
            }
          }

          @media (max-width: 992px) {
            margin-right: 0px;
          }
        }
      }
    }

    .brand-description + .actions {
      .button {
        margin-top: 0;
      }
    }
    .brand-description {
      padding: 0;

      p:first-child {
        margin: 0;
      }

      p {
        img {
          width: 250px;
        }
      }

      h2 {
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  @media (max-width: 530px) {
    .main-content-container {
      .brand-description {
        img {
          width: 160px !important;
          margin-top: 30px;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .main-content-container .brand-description img {
      width: 135px !important;
    }
  }

  .seo-body {
    position: relative;
    height: 100%;
    padding: 0;
    .seo-body-content {
      width: 80%;
      margin: 0 auto;

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .index-container-full {
    padding: 0;
    .seo-header {
      position: unset;
      width: 100%;
      padding:0 !important;

      .seo-arrow {
        display: none;
      }

      .seo-header-content {
        padding: 0 15px 0 15px;
      }
    }
  }
}

body[data-pnp-step] .index__header__logo {
  display: block !important;
}
